import api from '../../config/api';
class EmailController {
  /* eslint-disable */
  async send ( req ) {
    try {
      let response = await api.post( `/email/send`, req );
      return response?.data;
    } catch ( error ) {
      throw error;
    }
  }
}
export default new EmailController();