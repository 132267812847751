import api from '@/config/api';
class ContasAReceberController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/contasreceber/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      const response = await api.post( `/contasreceber/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.CRCB_ID;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async create ( req ) {
    try {
      const response = await api.post( '/contasreceber', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async update ( req ) {
    try {
      const response = await api.put( `/contasreceber/${ req.CRCB_ID }/${ req.CRCB_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async delete ( req ) {
    try {
      const response = await api.delete( `/contasreceber/${ req.CRCB_ID }/${ req.CRCB_EMP_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async listlow ( req ) {
    try {
      const response = await api.post( `/contasreceberbaixadas/${ req.EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async deleteInstallments ( req ) {
    try {
      const response = await api.delete( `/contasreceberparcelaspedidos/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new ContasAReceberController();