import api from '../config/api';
import OrdemServicoTotalizacao from './OrdemServicoTotalizacao';

class OrdemServicosServicosController {
  async index(req) {
      let response = await api.post(`/ordemservicoservico/${req.NROS}/${req.EMP_ID}/OS`, req);
      return response.data;
  }
  async create(req) {
      let response = await api.post(`/ordemservicoservico`, req);
      await OrdemServicoTotalizacao.calculate({ID: req.ORDI_ORDS_ID, EMP_ID: req.ORDI_ORDS_EMP_ID, SERIE: "OS" });
      return response.data;
  }
  async update(req) {
      let response = await api.put(`/ordemservicoservico/${req.ORDI_ORDS_ID}/${req.ORDI_ORDS_EMP_ID}/OS/${req.ORDI_ID}`, req);
      await OrdemServicoTotalizacao.calculate({ID: req.ORDI_ORDS_ID, EMP_ID: req.ORDI_ORDS_EMP_ID, SERIE: "OS" });
      return response.data;
  }
  async delete(req) {
      let response = await api.delete(`/ordemservicoservico/${req.ORDI_ORDS_ID}/${req.ORDI_ORDS_EMP_ID}/OS/${req.ORDI_ID}`);
      await OrdemServicoTotalizacao.calculate({ID: req.ORDI_ORDS_ID, EMP_ID: req.ORDI_ORDS_EMP_ID, SERIE: "OS" });
      return response.data
  }
}

export default new OrdemServicosServicosController();