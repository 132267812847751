import api from '../config/api';
class HistoricoPadraoController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post(`/historicopadrao/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/historicopadrao/${req.EMP_ID}`, req);
      if (!response.data.msg) {
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.HISP_ID;
          item.text = item.HISP_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/historicopadrao', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/historicopadrao/${req.HISP_ID}/${req.HISP_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/historicopadrao/${req.HISP_ID}/${req.HISP_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/historicopadrao/pesquisar/${req.HISP_ID}/${req.HISP_EMP_ID}`);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.HISP_ID;
          item.text = item.HISP_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async estrutura(req) {
    try {
      let response = await api.get(`/historicopadrao/estrutura`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new HistoricoPadraoController();