import api from '../config/api';
import { func } from '../functions/functions';

class OrcamentoController {
  async index(req) {
    let response = await api.post(`/orcamento/${req.EMP_ID}`, req);
    if (response.data.length > 0) {
      for (let i = 0; i < response?.data.length; i++) {
        response.data[i].ORCA_DTA_EMIS_FORMATED = func.convertDateTime(response.data[i].ORCA_DTA_EMIS)
        response.data[i].ORCA_DTA_SAIDA_FORMATED = func.convertDateTime(response.data[i].ORCA_DTA_SAID)
        response.data[i].ORCA_VLR_OS_FORMATED = func.convertMoney(response.data[i].ORCA_VLR_OS)
        response.data[i].ORCA_VLR_PECAS_FORMATED = func.convertMoney(response.data[i].ORCA_VLR_PECAS)
        response.data[i].ORCA_VLR_TOT_FORMATED = func.convertMoney(response.data[i].ORCA_VLR_TOT)
        response.data[i].ORDEM = i % 2 === 0 ? 0 : 1;
      }
      const result = response?.data || response;
      return await this.getLength(req, result);
    }
    else{
      return [];
    }
    
  }
  async getLength(req, res){
    let total = await api.post('/orcamento/registros/1', req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }
  async save(req) {
    let response = await api.post('/orcamento', req);
    return response.data;
  }
  async edit(req) {
    let response = await api.put(`/orcamento/${req.ORCA_ID}/${req.EMP_ID}/OS`, req);
    return response.data;
  }
  async delete(req) {
    let response = await api.delete(`/orcamento/${req.ORCA_ID}/${req.EMP_ID}/OS/${req.SEQ}`);
    return response.data;
  }
  async orcaAcrescimo(req) {
    let response = await api.post(`/orcamentoacrescimo/${req.ORCA_ID}/${req.EMP_ID}/OS`, req);
    return response.data
  }
  upload(data) {
    let formData = new FormData();
    formData.append("file", data.IMAGE);
    return api.put(`/orcamento/uploads/${data.ORCA_ID}/${data.EMP_ID}/OS/${data.POSITION}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  }
  imageDelete(data) { 
    return api.delete(`/orcamento/uploads/${data.ORCA_ID}/${data.EMP_ID}/OS/${data.POSITION}/${data.IMAGE}`);
  }
}
export default new OrcamentoController();