const orcamentoRoutes = [
    {
        path: '/orcamento',
        name: 'IndexOrcamento',
        meta: {
            requiresAuth: true,
            title: "Orçamento",
        },
        component: () => import('../../views/orcamento/IndexOrcamento.vue')
    },
    {
        path: '/orcamento/inserir',
        name: 'InsertOrcamento',
        meta: {
            requiresAuth: true,
            title: "Inserir Orçamento",
        },
        component: () => import('../../views/orcamento/InsertOrcamento.vue')
    },
    {
        path: '/orcamento/alterar',
        name: 'AlterOrcamento',
        meta: {
            requiresAuth: true,
            title: "Alterar Orçamento",
        },
        component: () => import('../../views/orcamento/EditOrcamento.vue')
    },
]
export default orcamentoRoutes;