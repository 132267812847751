import api from "../config/api";
class TiposServicoController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post('/tiposervico/registros', req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post("/tiposervico/filtro", req);
      if(!response.data.msg){
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.TIPS_ID;
          item.text = item.TIPS_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post("/tiposervico", req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/tiposervico/${req.TIPS_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/tiposervico/${req.TIPS_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/tiposervico/pesquisar/${req.TIPS_ID}`);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.TIPS_ID;
          item.text = item.TIPS_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new TiposServicoController();
