import { func } from "./functions";
export const manageInputs = {
    getByPositions(req) {
        const { inputs, positions } = req;
        const response = [];
        positions.forEach(index => {
            response.push(inputs[index]);
        });
        return response;
    },
    updateItems(req) {
        const { inputs, items } = req;
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        inputs.forEach(input => {
            if (hasOwnProperty.call(items, input.value)) {
                if (input.type.includes("combobox")) {
                    input.selected = null;
                    input.selected = input?.data?.find(item => item.id == items[input.value])
                }
                else if (input.type === 'checkbox') {
                    input.data = items[input.value] === 'S' || items[input.value] === 1 ? true : false
                }
                else if(input?.type === 'date' || input?.mask === "##/##/####"){
                    input.data = func.convertDateTime(items[input.value]);
                }
                else if (input?.mask === "##:##"){
                    input.data = func.convertTime(items[input.value]);
                }
                else if(input?.type === 'table'){
                    input.data = [];
                }
                else if(input?.type === 'money3'){
                    input.data = items[input.value];
                    input.dataFormated = func.convertMoney2(items[input.value]);
                }
                
                else {
                    input.data = items[input.value];
                }
            }
        });
        return inputs;
    },
    separateItemsCombobox(input, quantity){
        const hyphenMatches = input?.match(/-/g);
        if(!hyphenMatches || hyphenMatches?.length < quantity - 1){
            return null;
        }
        const parts = input.split(/\s*-\s*/);
        return {description: parts[quantity - 1] || ''};
    },
    verifyItems(req){
        const { inputs, items } = req;
            const isMatch = inputs.some(form => {
                if (!form.value) return false;
        
                let originalValue = items[form.value] || '';
                let newValue = form?.data || '';
        
                if (form.type.includes('combobox')) {
                    newValue = form?.selected?.id || '';
                } else if (form.type === 'date' || form.mask === '##/##/####') {
                    originalValue = func.convertDateTime(originalValue);
                } else if (form.type === 'time' || form.mask === '##:##') {
                    originalValue = originalValue || '00:00';
                }
                const result = originalValue?.toString()?.toUpperCase() != newValue?.toString()?.toUpperCase();
                if(result){
                console.log(`${form.value}: ${originalValue} - ${newValue}`)
                }
                return result;
            });
            return isMatch;
    }
}