import api from '../config/api';
class CondicoesVencimentoController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post(`/condicaovencimento/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    const defaultItems = {ORDENACAO:  "CNDV_DESCRICAO ASC"}
    const form = {...defaultItems, ...req}

    try {
      let response = await api.post(`/condicaovencimento/${req.EMP_ID}`, form);
      if(!response.data.msg){
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.CNDV_ID;
          item.text = item.CNDV_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/condicaovencimento', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/condicaovencimento/${req.CNDV_ID}/${req.CNDV_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/condicaovencimento/${req.CNDV_ID}/${req.CNDV_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async indexDias(req) {
    try {
      let response = await api.post(`/condicaovencimentodias/${req.ID}/${req.EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updatedDias(req) {
    try {
      let response = await api.post('/condicaovencimentodias', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteDias(req) {
    try {
      let response = await api.delete(`/condicaovencimentodias/${req.ID}/${req.EMP_ID}/${req.ID_DIAS}/${req.ID_MES}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new CondicoesVencimentoController();