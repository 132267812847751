const key = "mk2sl3nm4op577e2";
import CryptoJS from 'crypto-js'

export const cript = {
  encryptData: (data,) => {
    return CryptoJS.AES.encrypt(data, key).toString();
  },
  decryptData: (data) => {
    let bytes = CryptoJS.AES.decrypt(data, key)
    return bytes.toString(CryptoJS.enc.Utf8);
  },
  encryptSafeData: (data) => {
    const encrypted = CryptoJS.AES.encrypt(data, key).toString();
    return cript.base64UrlSafeEncode(encrypted);
  },
  decryptSafeData: (data) => {
    const base64 = cript.base64UrlSafeDecode(data);
    const bytes = CryptoJS.AES.decrypt(base64, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  },
  base64UrlSafeEncode: (base64) => {
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  },
  base64UrlSafeDecode: (base64) => {
    const padding = '='.repeat((4 - (base64.length % 4)) % 4);
    const base64WithPadding = base64 + padding;
    return base64WithPadding.replace(/-/g, '+').replace(/_/g, '/');
  }
}