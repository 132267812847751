import api from '../config/api';
import api_rel from '../config/api_rel';
class CondicionalController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( `/condicional/registros/${ req.EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      let response = await api.post( `/condicional/${ req.EMP_ID }`, req );
      return await this.getLength( req, response?.data );
    } catch ( error ) {
      throw error;
    }
  };
  async search ( req ) {
    try {
      let response = await api.post( `/condicional/${ req.COND_ID }/${ req.COND_EMP_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async created ( req ) {
    try {
      let response = await api.post( '/condicional', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updated ( req ) {
    try {
      let response = await api.put( `/condicional/${ req.COND_ID }/${ req.COND_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
    
  };
  async delete ( req ) {
    try {
      let response = await api.delete( `/condicional/${ req.COND_ID }/${ req.COND_EMP_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////
  async getLengthProduto ( req, res ) {
    try {
      let total = await api.post( `/condicionalprod/registros/${ req.COND_ID }/${ req.EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async indexProduto ( req ) {
    try {
      let response = await api.get( `/condicionalprod/${ req.COND_ID }/${ req.EMP_ID }`, req );
      return await this.getLengthProduto(req, response?.data);
    } catch ( error ) {
      throw error;
    }
  };
  async createdProduto ( req ) {
    try {
      let response = await api.post( '/condicionalprod', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updatedProduto ( req ) {
    try {
      let response = await api.put( `/condicionalprod/${ req.COND_ID }/${ req.COND_EMP_ID }/${ req.CNDP_ID }/${ req.PROD_ID }`, req);
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async deleteProduto ( req ) {
    try {
      let response = await api.delete( `/condicionalprod/${ req.COND_ID }/${ req.COND_EMP_ID }/${ req.CNDP_ID }/${ req.PROD_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////
  async getLengthServico ( req, res ) {
    try {
      let total = await api.post( `/condicionalserv/registros/${ req.COND_ID }/${ req.EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async indexServico ( req ) {
    try {
      let response = await api.get( `/condicionalserv/${ req.COND_ID }/${ req.EMP_ID }`, req );
      return await this.getLengthProduto(req, response?.data);
    } catch ( error ) {
      throw error;
    }
  };
  async createdServico ( req ) {
    try {
      let response = await api.post( '/condicionalserv', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updatedServico ( req ) {
    try {
      let response = await api.put( `/condicionalserv/${ req.COND_ID }/${ req.COND_EMP_ID }/${ req.CNDI_ID }/${ req.SERV_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async deleteServico ( req ) {
    try {
      let response = await api.delete( `/condicionalserv/${ req.COND_ID }/${ req.COND_EMP_ID }/${ req.CNDI_ID }/${ req.SERV_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////
  async getRelatorio ( req ) {
    try {
      let response = await api_rel.post( `/condicional/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new CondicionalController();