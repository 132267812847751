<template>
  <div class="z-index menus2">
    <v-navigation-drawer
      width="250"
      v-model="drawer"
      :mini-variant.sync="mini"
      fixed
      app
      color="primary"
    >
      <logo-section :mini="mini" :drawer="drawer"/>
      <menu-section :user="user" :items="items"/>
      <footer-menu-section :mini="mini" />
    </v-navigation-drawer>
    <AppBarSection :user="user" :empresaCNPJ="empresaCNPJ" :drawer="drawer" @openMenu="openMenu" @addFavourite="addFavourite"/>
  </div>
</template>
<script>
import api from "../../config/api";
import LogoSection from "@/components/layouts/navBar/LogoSection.vue";
import MenuSection from "@/components/layouts/navBar/MenuSection.vue";
import FooterMenuSection from "@/components/layouts/navBar/FooterMenuSection.vue";
import AppBarSection from "@/components/layouts/navBar/AppBarSection.vue";
export default {
  name: "NavBar",
  components: {
    LogoSection,
    MenuSection,
    FooterMenuSection,
    AppBarSection,
  },
  data: () => ({
    title: "BSSOFT",
    user: "",
    empresaCNPJ: "",
    drawer: true,
    mini: false,
    favourite: false,
    items: [],
    menuBackup: [],
    notifications: null,
    notifications2: [],
    notificacoesCount: 0,
  }),
  async created() {
    this.user = JSON.parse(this.$cript.decryptData(this.$store.state.user));
    this.user = this.user.USR_USUARIO;
    await this.mountMenu();
    await this.getProgramas();
    this.empresaCNPJ = JSON.parse(this.$cript.decryptData(this.$cookies.get("credenciais")));
    this.empresaCNPJ = this.empresaCNPJ.cpfcnpj;
  },

  methods: {
    openMenu() {
  
      if (this.$isMobile) {
        this.mini = false;
        this.drawer = !this.drawer;
      } else {
        this.mini = !this.mini;
        this.$store.commit("setMini", this.mini);
      }
    },
    async addFavourite () {
      let form = this.programas.find( item => item.PROG_DIR_WEB == this.$route.path );
      this.$route.meta.FAV = !this.$route.meta.FAV;
      form.PRGU_FAVORITO = this.$route.meta.FAV == true ? 1 : 0;
      form.PRGU_PROG_ID = form.PROG_ID;
      await api.put(`/programasusuario/${form.PROG_ID}/${this.user}`, form);
      await this.mountMenu();
    },
    async mountMenu() {
      const retorno = await api.get(`/programasusuario/montamenu/${this.user}`);
      this.items = retorno.data;
      this.$store.dispatch("saveFavoritos", this.items[0].NIVEL2);
    },
    async getProgramas() {
      const retorno = await api.get(`/programasusuario/${this.user}`);
      this.programas = retorno.data;
    },

    
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 1vh;
  height: 1vh;
}
.menus2 .v-list-item--dense,
.menus2 .v-list--dense .v-list-item {
  min-height: 40px !important;
  font-size: 12px;
}
</style>