  const correcaoEstoqueRoute = [{
    path: '/correcaoestoque',
    name: 'IndexStockCorrection',
    meta: {
      requiresAuth: true,
      title: "Correção de Estoque",
    },
    component: () => import('../../views/stock/stockCorrection/IndexStockCorrection.vue')
  }]
  export default correcaoEstoqueRoute;
