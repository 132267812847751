const numeracoesRoutes = [
  {
    path: '/numeracoes',
    name: 'IndexNumeracoes',
    meta: {
      requiresAuth: true,
      title: "Numerações",
    },
    component: () => import('../../views/numeracoes/IndexNumeracoes.vue')
  },
  {
    path: '/numeracoes/inserir',
    name: 'InsertNumeracoes',
    meta: {
      requiresAuth: true,
      title: "Cadastro de numerações",
    },
    component: () => import('../../views/numeracoes/InsertNumeracoes.vue')
  },
  {
    path: '/numeracoes/alterar',
    name: 'AlterNumeracoes',
    meta: {
      requiresAuth: true,
      title: "Editar numeração",
    },
    component: () => import('../../views/numeracoes/AlterNumeracoes.vue')
  },
]
export default numeracoesRoutes;