import api from '../../config/api';
class RequisicoesProdutoController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( `/requisicaoproduto/registros/${ req.REQU_ID }/${ req.REQU_EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      let response = await api.post( `/requisicaoproduto/${ req.REQU_ID }/${ req.REQU_EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async created ( req ) {
    try {
      let response = await api.post( '/requisicaoproduto', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updated ( req ) {
    try {
      let response = await api.put( `/requisicaoproduto/${ req.REQP_REQU_ID }/${ req.REQP_REQU_EMP_ID }/${ req.REQP_ID }/${ req.REQP_PROD_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async delete ( req ) {
    try {
      let response = await api.delete( `/requisicaoproduto/${ req.REQU_ID }/${ req.REQU_EMP_ID }/${ req.REQP_ID }/${ req.REQP_PROD_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async view ( req ) {
    try {
      let response = await api.get( `/requisicaoproduto/${ req.REQU_ID }/${ req.REQU_EMP_ID }/${ req.REQP_ID }`);
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new RequisicoesProdutoController();