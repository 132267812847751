import api from '../config/api';

class EquipamentosController {

  async getLength(req, res){
    let total = await api.post('/equipamento/registros/1', req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }

  async index(req) {
    let response = await api.post(`/equipamento/1`, req);
    if(response.data.length > 0){
      response?.data?.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.text = item.EQPT_SERIE || item.MDEQ_DESCRICAO + ' ' +   item.MREQ_DESCRICAO;
        item.id = item.EQPT_ID;
      });
    }
    const result = response?.data || response;
    return await this.getLength(req, result);
  }

  async save(req) {
    let response = await api.post('/equipamento/', req);
    return response.data;
  }

  async edit(req) {
    let response = await api.put(`/equipamento/${req.EQPT_ID}/1`, req);
    return response.data;
  }

  async delete(req) {
    let response = await api.delete(`/equipamento/${req.ID}/${req.EMP_ID}`);
    return response;
  }
  async search(req) {
    const response = await api.get(`/equipamento/pesquisar/${req}/1`);
    return response.data;
  }

// imagens
async getImagensEquipamento(req){
  const response = await api.post(`/equipamentosimg/${req.EQPT_ID}/${req.EMP_ID}/`);
  return response.data;
}
}

export default new EquipamentosController();