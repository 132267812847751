import api from '../config/api';

class MarcaEquipamentosController {

  async getLength(req, res) {
    let total = await api.post('/marcaequipamento/registros/1', req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }

  async index(req) {
    const response = await api.post(`/marcaequipamento/${req.EMP_ID}`, req);
    if (response.data.length > 0) {
      response?.data?.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.text = item.MREQ_DESCRICAO;
        item.id = item.MREQ_ID;
      });
    }
    const result = response?.data || response;
    return await this.getLength(req, result);
  }

  async save(req) {
    const response = await api.post(`/marcaequipamento`, req);
    return response.data;
  }

  async edit(req) {
    const response = await api.put(`/marcaequipamento/${req.id}/${req.MREQ_EMP_ID}`, req);
    return response.data;
  }

  async delete(req) {
    const response = await api.delete(`/marcaequipamento/${req.ID}/${req.EMP_ID}`);
    return response.data;
  }
}

export default new MarcaEquipamentosController();