import api from '../config/api';
class ColaboradoresController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post(`/colaborador/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/colaborador/${req.EMP_ID}`, req);
      if(!response.data.msg) {
        response.data.forEach((element, index) => {
          element.ORDEM = index % 2 === 0 ? 0 : 1;
          element.id = element.COLA_ID;
          element.text = element.COLA_NOME;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/colaborador', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/colaborador/${req.COLA_ID}/${req.COLA_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/colaborador/${req.COLA_ID}/${req.COLA_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/colaborador/pesquisar/${req.FILTRO}/${req.EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new ColaboradoresController();