const AtualizaBancoRoutes = [
  {
    path: '/atualizabanco/inserir',
    name: 'InsertAtualizaBanco',
    meta: {
      requiresAuth: true,
      title: "Inserir Atualização ao Banco de Dados",
    },
    component: () => import('../../views/atualizabanco/InsertAtualizaBanco.vue')
  },
  {
    path: '/atualizabanco/alterar',
    name: 'AlterAtualizaBanco',
    meta: {
      requiresAuth: true,
      title: "Alterar Atualização do Banco de Dados",
    },
    component: () => import('../../views/atualizabanco/AlterAtualizaBanco.vue')
  },
  {
    path: '/atualizabanco',
    name: 'IndexAtualizaBanco',
    meta: {
      requiresAuth: true,
      title: "Atualizações do Banco de Dados",
    },
    component: () => import('../../views/atualizabanco/IndexAtualizaBanco.vue')
  }
]
export default AtualizaBancoRoutes;