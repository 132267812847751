const conexaoLocalRoutes = [
    {
        path: '/conexaolocal/:id',
        name: 'AlterColaborador',
        meta: {
          title: "Conectar BS Local",
        },
        component: () => import('../../views/serverLocal/indexServerLocal.vue')
      },
]
export default conexaoLocalRoutes;