const relatoriosRoutes = [
  {
    path: '/relatoriovendadeservicos',
    name: 'RelatorioVendaDeServicos',
    meta: {
      requiresAuth: true,
      title: "Relátorio de Venda de Serviços por Período",
    },
    component: () => import('../../../views/relatorios/RelatorioVendaDeServicos.vue')
  },
  {
    path: '/relatoriovendadeprodutos',
    name: 'RelatorioVendaDeProdutos',
    meta: {
      requiresAuth: true,
      title: "Relátorio de Venda de Produtos por Período",
    },
    component: () => import('../../../views/relatorios/RelatorioVendaDeProdutos.vue')
  },
  {
    path: '/relatoriocurvadeprodutos',
    name: 'RelatorioCurvaDeProdutos',
    meta: {
      requiresAuth: true,
      title: "Relatório Curva de Produtos",
    },
    component: () => import('../../../views/relatorios/RelatorioCurvaDeProdutos.vue')
  },
  {
    path: '/relatoriodevendas',
    name: 'RelatorioDeVendas',
    meta: {
      requiresAuth: true,
      title: "Relatório de Vendas",
    },
    component: () => import('../../../views/relatorios/RelatorioDeVendas.vue')
  },
  {
    path: '/relatorioderecebimentos',
    name: 'RelatorioRecebimentos',
    meta: {
      requiresAuth: true,
      title: "Relatório de Recebimentos",
    },
    component: () => import('../../../views/relatorios/RelatorioRecebimentos.vue')
  },
  {
    path: '/relatorioderequisicoes',
    name: 'RelatorioDeRequisicoes',
    meta: {
      requiresAuth: true,
      title: "Relatório de Requisições",
    },
    component: () => import('../../../views/relatorios/RelatorioDeRequisicoes.vue')
  },
  {
    path: '/relatoriodeosporsetor',
    name: 'RelatorioDeOSporSetor',
    meta: {
      requiresAuth: true,
      title: "Relatório de OS por Setor",
    },
    component: () => import('../../../views/relatorios/RelatorioDeOSporSetor.vue')
  },
  {
    path: '/relatorioordemdeservico',
    name: 'RelatorioOrdemDeServico',
    meta: {
      requiresAuth: true,
      title: "Relatório Ordem de Serviço",
    },
    component: () => import('../../../views/relatorios/RelatorioOrdemDeServico.vue')
  },
]
export default relatoriosRoutes;
