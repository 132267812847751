const formasPagamentoRoutes = [
  {
    path: '/formaspagamento',
    name: 'IndexFormasPagamento',
    meta: {
      requiresAuth: true,
      title: "Formas de Pagamento",
    },
    component: () => import('../../views/formasPagamento/IndexFormasPagamento.vue')
  },
  {
    path: '/formaspagamento/inserir',
    name: 'InsertFormasPagamento',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Formas de Pagamento",
    },
    component: () => import('../../views/formasPagamento/InsertFormasPagamento.vue')
  },
  {
    path: '/formaspagamento/alterar',
    name: 'AlterFormasPagamento',
    meta: {
      requiresAuth: true,
      title: "Editar Forma de Pagamento",
    },
    component: () => import('../../views/formasPagamento/AlterFormasPagamento.vue')
  },
]
export default formasPagamentoRoutes;