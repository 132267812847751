import api from '../config/api';
class FormasPagamentoController {
  /* eslint-disable */
  async getLength(req, res){
    try{ 
      let total = await api.post(`/formapagamento/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw  error
    }
  }
  async index(req) {
    const defaultItems = {ORDENACAO:  "FRMP_DESCRICAO ASC"}
    const form = {...defaultItems, ...req}
    try {
      let response = await api.post(`/formapagamento/${form.EMP_ID}`, form);
      if (!response.data.msg) {
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.FRMP_ID;
          item.text = item.FRMP_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/formapagamento', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/formapagamento/${req.FRMP_ID}/${req.FRMP_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/formapagamento/${req.FRMP_ID}/${req.FRMP_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async tef () {
    try {
      let response = await api.post(`/tefadquirinte/filtro`);
      if ( !response.data.msg ) {
        response.data.forEach( item => {
          item.id = item.TEFA_ID;
          item.text = item.TEFA_ADQUIRINTE;
        });
      }
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
}
export default new FormasPagamentoController();