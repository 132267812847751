import api from '../config/api';

class LoginController {
  async doAuth(req) {
      return await api.post('usuarios/sessions', req);  
    }
  logout(req) {
    req.session.destroy();
  }
  async checkEmpresa(req){
    let response = await api.get(`empresa/consulta/${req}`);
    return response.data[0];
  }
}

export default new LoginController();
