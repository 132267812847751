const ordemServico = [
  //ORDEM SERVICO / VEICULO PADRÃO  
  {
      path: '/ordensdeservico',
      name: 'IndexOrderServiceDefault',
      meta: {
        requiresAuth: true,
        title: "Ordem de Serviço",
        subheader: [
          { text: "Inicio", route: "/" },
          { text: "Ordem de Serviço", route: "/ordemservico" },
        ],
        buttons: [
          { text: "", icon: "mdi-plus", route: "/ordensdeservico/inserir", color: "#21c1d6" }
        ],
  
      },
      component: () => import('../../views/ordemServico/ordensServico/IndexOrdensServico.vue')
    },
    {
      path: '/ordensdeservico/inserir',
      name: 'InsertOrderServiceDefault',
      meta: {
        requiresAuth: true,
        title: "Cadastro de Ordem de Serviço",
      },
      component: () => import('../../views/ordemServico/ordensServico/ManageOrdensServico.vue')
    },
    {
      path: '/ordensdeservico/alterar',
      name: 'AlterOrderServiceDefault',
      meta: {
        requiresAuth: true,
        title: "Alterar Ordem de Serviço",
      },
      component: () => import('../../views/ordemServico/ordensServico/ManageOrdensServico.vue')
    },
  //ORDEM LE
      {
    path: '/ordemservico',
    name: 'IndexOrderService',
    meta: {
      requiresAuth: true,
      title: "Ordem de Serviço",
      subheader: [
        { text: "Inicio", route: "/" },
        { text: "Ordem de Serviço", route: "/ordemservico" },
      ],
      buttons: [
        { text: "", icon: "mdi-plus", route: "/ordemservico/inserir", color: "#21c1d6" }
      ],

    },
    component: () => import('../../views/ordemServico/orderEqptLE/IndexOrderService.vue')
  },
  {
    path: '/ordemservico/inserir',
    name: 'InsertOrderService',
    meta: {
      requiresAuth: true,
      title: "Ordem de Serviço",

    },
    component: () => import('../../views/ordemServico/orderEqptLE/InsertOrderService.vue')
  },
  {
    path: '/ordemservico/alterar',
    name: 'EditOrderService',
    meta: {
      requiresAuth: true,
      title: "Ordem de Serviço",
    },
    component: () => import('../../views/ordemServico/orderEqptLE/EditOrderService.vue')
  },

]
export default ordemServico;