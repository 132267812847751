import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { cript } from '@/functions/criptografia.js';
import store from '../store/store'

Vue.use(Vuetify);
let style = JSON.parse(cript.decryptData(store.state.style));
export default new Vuetify({
  theme: {
    themes: {
      light: style.colors
    }
  }

});
