import api from '../config/api';

class AgendamentoVeiculosController {
  async index(req) {
    let response = await api.post(`/agdoviagem/1`, req);
    const result = response?.data || response;
    return result;
  }
  async filter(req){
    let response = await api.get(`/agdoviagem/${req}/1`);
    return response?.data[0];
  }
  async save(req) {
    let response = await api.post(`/agdoviagem`, req);
    return response.data;
  }
  async edit(req, id) {
    try{
      let response = await api.put(`/agdoviagem/${id}/1`, req);
      return response?.data;
    }
    catch(error){
      console.log(error)
    }

  }
  async getImagens(req) {
    let response = await api.post(`/agdoviagemimg/${req}/1`)
    return response.data;
  }
  async report(req) {
    let response = await api.post(`/agdoviagem/relatorio/${req.EMP_ID}`, req);
    return response.data;
  }
  async reportFat(req) {
    let response = await api.post(`/agdoviagem/relatoriofat/${req.EMP_ID}`, req);
    return response.data;
  }
  async geraPedido(req) {
    let response = await api.post('/gerarpedido/agdoviagem', req);
    return response.data;
  }

  //no auth

  async reservasBaitaMundo(req){
    let response = await api.post('/baitaMundo/reservas', req, { headers: {clienteid: 691}})
    return response.data
  }

  async veiculosBaitaMundo(req){
    let response = await api.post('/baitaMundo/veiculos', req, { headers: {clienteid: 691}})
    return response.data;

  }
}
export default new AgendamentoVeiculosController();