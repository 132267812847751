import api from '../config/api';

class MarcaVeiculosController {
  async getLength(req, res){
    let total = await api.post('/marcaveiculo/registros/1', req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }
  async index(req) {
    let response = await api.post('/marcaveiculo/1', req);
    if(response.data.length > 0){
      response?.data?.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.text = item.MRCV_DESCRICAO
        item.id = item.MRCV_ID 
      });
    }
    const result = response?.data || response;
    return await this.getLength(req, result);
  }
  async save(req) {
    let response = await api.post('/marcaveiculo', req);
    return response.data;
  }
  async edit(req) {
    let response = await api.put(`/marcaveiculo/${req.MRCV_ID}/${req.MRCV_EMP_ID}`, req);
    return response.data;
  }
  async delete(req){
    let response = await api.delete(`/marcaveiculo/${req.ID}/${req.EMP_ID}`);
    return response.data;
  }
}
export default new MarcaVeiculosController();