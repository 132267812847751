const ncmRoutes = [
    {
        path: '/ncm',
        name: 'IndexNcm',
        meta: {
          requiresAuth: true,
          title: "Consulta NCM",
        },
        component: () => import('../../views/ncm/IndexNcm.vue')
      },
      {
        path: '/ncm/inserir',
        name: 'InsertNcm',
        meta: {
          requiresAuth: true,
          title: "Cadastro de NCM",
        },
        component: () => import('../../views/ncm/InsertNcm.vue')
      },
      {
        path: '/ncm/alterar',
        name: 'AlterNcm',
        meta: {
          requiresAuth: true,
          title: "Editar NCM",
        },
        component: () => import('../../views/ncm/AlterNcm.vue')
      }
]
export default ncmRoutes;