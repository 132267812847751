import api from '../config/api';
class SolicitacaoController {
  /* eslint-disable */
  async indexSolicitacao(req) {
    try {
      let response = await api.post(`/solicitacao/filtro`, req);
      return response?.data;
    } catch(error) {
      throw error
    }
  }
  async saveSolicitacao(req) {
    try {
      let response = await api.post('/solicitacao', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updatedSolicitacao(req) {
    try {
      let response = await api.put(`/solicitacao/${req.soli_id}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteSolicitacao(req) {
    try {
      let response = await api.delete(`/solicitacao/${req.ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  // solicitacoes (filho)
  async indexSolicitacaoFilhos(req) {
    try {
      let response = await api.post(`/solicitacaofilhos/filtro`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async saveSolicitacaoFilhos(req) {
    try {
      let response = await api.post('/solicitacaofilhos', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updatedSolicitacaoFilhos(req) {
    try {
      let response = await api.put(`/solicitacaofilhos/${req.solf_id}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteSolicitacaoFilhos(req) {
    try {
      let response = await api.delete(`/solicitacaofilhos/${req.ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  // anexos
  async indexImage(req) {
    try {
      let response = await api.post(`/solicitacaoanexos/filtro`, req);
      return response?.data;
    } catch(error) {
      throw error
    }
  }
  updatedImage(req) {
    try {
      let formData = new FormData();
      formData.append("file", req.soan_nome_arq);
      formData.append("soan_usr_id", req.soan_usr_id);
      formData.append("soan_soli_id", req.soan_soli_id);
      return api.post(`/solicitacaoanexos`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch(error) {
      throw error
    }
  }
  async deleteImage(req) {
    try {
      let response = await api.delete(`/solicitacaoanexos/${req.ID}/${req.NOME_IMAGE}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  // TAREFAS
  async indexList(req) {
    try {
      let response = await api.post(`/solicitacaofilhoslist/filtro`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async saveList(req) {
    try {
      let response = await api.post('/solicitacaofilhoslist', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updatedList(req) {
    try {
      let response = await api.put(`/solicitacaofilhoslist/${req.sofl_id}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteList(req) {
    try {
      let response = await api.delete(`/solicitacaofilhoslist/${req.ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  // chat
  async indexChat(req) {
    try {
      let response = await api.post(`/solicitacaofilhoschat/filtro`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async createChat(req) {
    try {
      let formData = new FormData();
      formData.append("file", req.sofc_img);
      formData.append("sofc_usr_id", req.sofc_usr_id);
      formData.append("sofc_solf_id", req.sofc_solf_id);
      formData.append("sofc_descricao", req.sofc_descricao);
      return api.post(`/solicitacaofilhoschat`, formData, {
          headers: {
              "Content-Type": "multipart/form-data"
          },
      });
    } catch(error) {
      throw error
    }
  }
  async deleteChat(req) {
    try {
      let response = await api.delete(`/solicitacaofilhoschat/${req.ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  // Setor
  async indexSetor(req) {
    try {
      let response = await api.post(`/setorsolicitacao/filtro`, req);
      response.data.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.id = item.stso_id;
        item.text = item.stso_descricao;
      });
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async saveSetor(req) {
    try {
      let response = await api.post('/setorsolicitacao', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updatedSetor(req) {
    try {
      let response = await api.put(`/setorsolicitacao/${req.stso_id}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteSetor(req) {
    try {
      let response = await api.delete(`/setorsolicitacao/${req.ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  // situacao
  async indexSituacao(req) {
    try {
      let response = await api.post(`/solicitacaofilhossituacao/filtro`, req);
      response.data.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.id = item.situ_id;
        item.text = item.situ_descricao;
      });
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async saveSituacao(req) {
    try {
      let response = await api.post('/solicitacaofilhossituacao', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updatedSituacao(req) {
    try {
      let response = await api.put(`/solicitacaofilhossituacao/${req.situ_id}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteSituacao(req) {
    try {
      let response = await api.delete(`/solicitacaofilhossituacao/${req.ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  ////////////////////////////////////////////////////////////////////////////// projeto
  async indexProjeto(req) {
    try {
      let response = await api.post(`/solicitacao/projeto/filtro`, req);
      response.data.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.id = item.sopr_id;
        item.text = item.sopr_descricao;
      });
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async saveProjeto(req) {
    try {
      let response = await api.post(`/solicitacao/projeto`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updateProjeto(req) {
    try {
      let response = await api.put(`/solicitacao/projeto/${req.sopr_id}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteProjeto(req) {
    try {
      let response = await api.delete(`/solicitacao/projeto/${req.sopr_id}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new SolicitacaoController();