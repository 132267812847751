const contasAReceberRoutes = [
  {
    path: '/contasareceber/inserir',
    name: 'InsertContasAReceber',
    meta: {
      requiresAuth: true,
      title: "Cadastro Contas a Receber",
    },
    component: () => import('../../views/contasAReceber/ManageContasAReceber.vue')
  },
  {
    path: '/contasareceber/alterar',
    name: 'AlterContasAReceber',
    meta: {
      requiresAuth: true,
      title: "Alterar Contas a Receber",
    },
    component: () => import('../../views/contasAReceber/ManageContasAReceber.vue')
  },
  {
    path: '/contasareceber',
    name: 'IndexContasAReceber',
    meta: {
      requiresAuth: true,
      title: "Contas a Receber",
    },
    component: () => import('../../views/contasAReceber/IndexContasAReceber.vue')
  }
]
export default contasAReceberRoutes;