import api from '../config/api';
// import { func } from '../functions/functions';

class OrcamentoGeradorController {
  async pedidoFat(req) {
    let response = await api.post(`/orcamentogerapedidofat/${req.ORCA_ID}/${req.EMP_ID}/OS/${req.MODELO}/${req.TIPO}/${req.USER}`);
    return response.data;
  }
  async requisicao(req) {
    let response = await api.post(`/orcamentogerarequisicao/${req.ORCA_ID}/${req.EMP_ID}/OS`);
    return response.data;
  }
  async pedidoFab(req) {
    let response = await api.post(`/orcamentogerapedidofab/${req.ORCA_ID}/${req.EMP_ID}/OS`);
    return response.data;
  }
  async PDV(req) {
    let response = await api.post(`/orcamentogerapdv/${req.ORCA_ID}/${req.EMP_ID}/OS`);
    return response.data;
  }
  async OS(req) {
    let response = await api.post(`/orcamentogeraos/${req.ORCA_ID}/${req.EMP_ID}/OS`);
    return response.data;
  }
  async duplicar(req) {
    let response = await api.post(`/orcamentoduplicar/${req.ORCA_ID}/${req.EMP_ID}/OS`);
    return response.data;
  }
}
export default new OrcamentoGeradorController();