import api from '../config/api';

class OrderSetorController {
  async index(req) {
    let response = await api.post(`/ordemservicoservicosetor/${req.NROS}/${req.EMP_ID}/OS/${req.ID_SERV}`, req);
    if(response.data.length > 0){
      response?.data?.forEach((item, index) => {
        item["ORDEM"] = index % 2 === 0 ? 0 : 1;
      });
    }
    const result = response?.data || response;
    return result;
   
  }

  async save(req) {
    let response = await api.post('/ordemservicoservicosetor', req);
   
    return response.data;
  }

//   async edit(req) {
//     let response = await api.put(`/cidades/${req.id}/1`, req);
//     return response.data;
//   }

  async delete(req) {
    let response = await api.delete(`/ordemservicoservicosetor/${req.NROS}/${req.EMP_ID}/OS/${req.ID_SERV}/${req.ID_SETOR}`);
    return response.data;
  }
//   async search(req) {
//     if(req){
//     const response = await api.get(`/cidades/pesquisar/${req}/1`);
//     return response.data;
//     }
   
//   }

}

export default new OrderSetorController();