import api from '../config/api';
class FuncoesController {
  /* eslint-disable */
  async getLength(req, res) {
    try {
      let total = await api.post(`/funcao/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/funcao/${req.EMP_ID}`, req);
      if(!response.data.msg){
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.FUNC_ID;
          item.text = item.FUNC_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/funcao', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/funcao/${req.FUNC_ID}/${req.FUNC_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/funcao/${req.FUNC_ID}/${req.FUNC_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/funcao/pesquisar/${req.FUNC_ID}/${req.FUNC_EMP_ID}`);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.FUNC_ID;
          item.text = item.FUNC_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new FuncoesController();