import api from '../../../config/api';
class CarregamentoBarrisController {
  /* eslint-disable */
  async index(req) {
    try {
      let response = await api.post(`/carregamentobarrilp/filtros`, req);
      if (!response.data.msg) {
        response.data.forEach((item, index) => {
          item["ORDEM"] = index % 2 == 0 ? 0 : 1;
        });
      }
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async created(req) {
    try {
      let response = await api.post('/carregamentobarrilp', req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/carregamentobarrilp/${req.CARP_CARB_ID}/${req.CARP_BAR_ID}`, req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/carregamentobarrilp/${req.ID}/${req.BAR_ID}`);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
}
export default new CarregamentoBarrisController();