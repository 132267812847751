

import { register } from 'register-service-worker'

const version = process.env.VUE_APP_VERSION; // Pegue a versão do package.json via variável de ambiente

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        `App (versão ${version}) está sendo servido a partir do cache por um service worker.\n` +
        'Para mais detalhes, visite https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log(`Service worker registrado para a versão ${version}.`)
    },
    cached () {
      console.log(`Conteúdo para a versão ${version} foi armazenado em cache para uso offline.`)
    },
    updatefound () {
      console.log('Novo conteúdo está sendo baixado.')
    },
    // updated () {
    //   console.log(`Novo conteúdo para a versão ${version} está disponível; por favor, atualize.`)
    //   if (confirm('Nova versão disponível. Atualizar agora?')) {
    //     window.location.reload()
    //   }
    // },
    offline () {
      console.log('Nenhuma conexão com a internet encontrada. O app está rodando em modo offline.')
    },
    error (error) {
      console.error('Erro durante o registro do service worker:', error)
    }
  })
}
