import api from '../config/api';

class MarcaProdutosController {

  async getLength(req, res){
    let total = await api.post('/marca/registros/1', req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }

  async index(req) {
    let response = await api.post(`/marca/${req.EMP_ID}`, req);
    if(response.data.length > 0){
      response?.data?.forEach((item, index) => {
        item["ORDEM"] = index % 2 === 0 ? 0 : 1;
        item.text = item.MARC_DESCRICAO;
        item.id = item.MARC_ID;
      });
    }
    const result = response?.data || response;
    return await this.getLength(req, result);
  }
    
    async create(req) {
      let response = await api.post('/marca', req);
      return response.data;
    }

    async getLenght(req) {
      let response = await api.post(`/marca/registros/${req.EMP_ID}`);
      return response.data[0];
    }

    async search(req) {
      let response = await api.get(`/marca/pesquisar/${req.ID}/${req.EMP_ID}`);
      return response.data;
    }

    async update(req) {
      let response = await api.put(`/marca/${req.MARC_ID}/${req.MARC_EMP_ID}`, req);
      return response.data;
    }

    async delete(req) {
      let response = await api.delete(`/marca/${req.ID}/${req.EMP_ID}`);
      return response.data;
    }
    
}
export default new MarcaProdutosController();