import api from '../config/api';
class CidadesController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post(`/cidades/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/cidades/${req.EMP_ID}`, req);
      if(!response.data.msg) {
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id =  item.CID_ID;
          item.text = item.CID_DESCRICAO + ' - ' + item.EST_SIGLA;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/cidades', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/cidades/${req.CID_ID}/${req.CID_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/cidades/${req.CID_ID}/${req.CID_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/cidades/pesquisar/${req.FILTRO}/${req.EMP_ID}`);
      if(!response.data.msg) {
        response.data.forEach((item, index) => {
          item.id =  item.CID_ID;
          item.text = item.CID_DESCRICAO + ' - ' + item.EST_SIGLA;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new CidadesController();