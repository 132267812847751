<template>
  <v-card
  :width="mini ? '86%' : '96%'"
  height="60px"
  class="fixed-logo-cliente d-flex align-center"
  :class="!mini ? 'justify-center' : 'overflow-hidden'"
  >
    <div v-if="image">
      <img
      :src="image"
      height="50px"
      ref="logoImage"
      style="max-width: auto; max-height: 50px; padding: 5px;"
      @load="verificaLogo"
      />
      <v-card-text v-if="!mini && imageWidth < 100">
        <div>{{ $store.state.empresa }}</div>
      </v-card-text>
    </div>
    <div v-else class="d-flex align-center">
      <img
      src="../../../assets/images/users/perfil-de-usuario.png"
      height="50px"
      />
      <v-card-text v-if="!mini">
        <div>{{ $store.state.empresa }}</div>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
import routes from "@/router/routes";
export default {
    props: {
    mini: Boolean,
  },
  name: "FooterMenuSection",
  data: () => ({
    env: null,
    image: null,
    id: null,
    imageWidth: null,
  }),
  async created() {
    this.env = process.env.VUE_APP_BACKEND;
    this.userDados = JSON.parse( this.$cript.decryptData( this.$store.state.user ));
    this.id = this.$cript.decryptData(this.$store.state.id);
    await this.getEmpresa();
  },
  methods: {
    verificaLogo() {
      this.imageWidth = this.$refs.logoImage.naturalWidth;
    },
    async getEmpresa() {
      await routes.EmpresasController.index()
      .then(data => {
        let img = data.find( check => check.EMP_ID == this.userDados.USR_EMP_ID )?.EMP_IMG_LOGO_REL;
        if( img) {
          this.image = `${this.env}/uploads/${this.id}/${img}`;
        }
      }).catch(error => {
        console.log(error);
      });
    },
  }
}
</script>
<style scoped>
.fixed-logo-cliente {
  position: fixed;
  bottom: 0;
  z-index: 3;
}
</style>