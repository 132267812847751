import api from '../config/api';
class EmpresaController {
  /* eslint-disable */
  async index () {
    let response = await api.get('/empresas/1');
    return response.data[0];
  }
  async indexMysql (req) {
    let response = await api.post('/empresa/filtro', req);
    return response.data;
  }
  // routes.post('/empresa/filtro', list.list);
  // routes.get('/empresa/:cpfcnpj', list.view);
  async create ( req ) {
    try {
      let response = await api.post( '/empresa', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async update ( req ) {
    try {
      let response = await api.put( `/empresa/${ req.id }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async delete ( req ) {
    try {
      let response = await api.delete( `/empresa/${ req.id }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
}
export default new EmpresaController();