import api from '@/config/api';
import api_rel from '../config/api_rel';
class ConsultaProdutoController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api_rel.post(`/consultaprodutos/registros`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api_rel.post(`/consultaprodutos`, req);
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error;
    }
  }
  async estoque ( req ) {
    try {
      let response = await api.get( `/produtosmultiestoquebd/${req.ID}/${req.EMP_ID}` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
}
export default new ConsultaProdutoController();