const funcoesRoutes = [
  {
    path: '/funcoes',
    name: 'IndexFuncoes',
    meta: {
      requiresAuth: true,
      title: "Funções",
    },
    component: () => import('../../views/funcoes/IndexFuncoes.vue')
  },
  {
    path: '/funcoes/inserir',
    name: 'InsertFuncoes',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Funções",
    },
    component: () => import('../../views/funcoes/InsertFuncoes.vue')
  },
  {
    path: '/funcoes/alterar',
    name: 'AlterFuncoes',
    meta: {
      requiresAuth: true,
      title: "Editar Função",
    },
    component: () => import('../../views/funcoes/AlterFuncoes.vue')
  },
];
export default funcoesRoutes;