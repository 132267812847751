import LoginController from '@/controllers/LoginController';
import EstadosController from '@/controllers/EstadosController';
import CidadesController from '@/controllers/CidadesController';
import OrdersController from '@/controllers/OrdersController';
import OrderSetorController from '@/controllers/OrderSetorController';
import ClientesController from '@/controllers/clientes/ClientesController';
import EquipamentosController from '@/controllers/EquipamentosController';
import CondicaoPagamentoController from '@/controllers/CondicaoPagamentoController';
import ServicosController from '@/controllers/ServicosController';
import PecasServicesController from '@/controllers/PecasServicesController'
import UploadController from '@/controllers/UploadController';
import VeiculosController from '@/controllers/VeiculosController';
import EmpresaController from '@/controllers/EmpresaController';
import ColaboradoresController from '@/controllers/ColaboradoresController';
import ProdutoController from '@/controllers/ProdutoController';
import ParametrosGenericosController from '@/controllers/ParametrosGenericosController';
import OrderServicoMecanicoController from '@/controllers/OrderServicoMecanicoController';
import CorrecaoEstoqueController from '@/controllers/CorrecaoEstoqueController';
import GruposController from '@/controllers/GruposController';
import SubGruposController from '@/controllers/SubGruposController';
import NcmController from '@/controllers/NcmController';
import MarcaController from '@/controllers/MarcaController';
import ObservacoesController from '@/controllers/ObservacoesController';
import FornecedoresController from '@/controllers/FornecedoresController';
import OrcamentoController from '@/controllers/OrcamentoController';
import OrcamentoServController from '@/controllers/OrcamentoServController';
import OrcamentoProdController from '@/controllers/OrcamentoProdController';
import OrcamentoGerador from '@/controllers/OrcamentoGeradorController';
import TiposServicoController from '@/controllers/TiposServicoController';
import SetoresServicoController from '@/controllers/SetoresServicoController';
import LocaisController from '@/controllers/LocaisController';
import UnidadesController from '@/controllers/UnidadesController';
import MarcaProdutosController from '@/controllers/MarcaProdutosController';
import FormasPagamentoController from '@/controllers/FormasPagamentoController';
import EmpresasController from '@/controllers/EmpresasController';
import PaisesController from '@/controllers/PaisesController';
import NaturezasOperacaoController from '@/controllers/NaturezasOperacaoController';
import FuncoesController from '@/controllers/FuncoesController';
import CondicoesVencimentoController from '@/controllers/CondicoesVencimentoController';
import BancosController from '@/controllers/BancosController';
import CoresController from '@/controllers/CoresController';
import NumeracoesController from '@/controllers/NumeracoesController';
import TamanhosController from '@/controllers/TamanhosController';
import ProgramasController from '@/controllers/ProgramasController';
import UsuariosController from '@/controllers/UsuariosController';
import ClientesHistoricoController from '@/controllers/clientes/ClientesHistoricoController';
import ProdutosEspeciaisController from '@/controllers/ProdutosEspeciaisController';
import ContratosClientesController from '@/controllers/clientes/ContratosClientesController';
import ContratosEquipamentoController from '@/controllers/ContratosEquipamentoController';
import ContratoClientesController from '@/controllers/clientes/ContratoClientesController';
import GruposHistoricoPadraoController from '@/controllers/GruposHistoricoPadraoController';
import GruposServicoController from '@/controllers/GruposServicoController';
import HistoricosController from '@/controllers/HistoricosController';
import HistoricoPadraoController from '@/controllers/HistoticoPadraoController';
import ClientesFamiliarController from '@/controllers/clientes/ClientesFamiliarController';
import ClientesServicosController from '@/controllers/clientes/ClientesServicosController';
import ClientesVendasController from '@/controllers/clientes/ClientesVendasController';
import SolicitacaoController from '@/controllers/SolicitacaoController';
import OrdemPecasController from "@/controllers/OrdemPecasController";
import OrdemPecasProdController from '@/controllers/OrdemPecasProdController';
import OrdemPecasServController from '@/controllers/OrdemPecasServController';
import AgendamentoServicoController from '@/controllers/AgendamentoServicoController';
import ModeloEquipamentosController from '@/controllers/ModeloEquipamentosController';
import TipoEquipamentosController from '@/controllers/TipoEquipamentosController';
import MarcaEquipamentosController from '@/controllers/MarcaEquipamentosController';
import TipoEquipamentosServController from '@/controllers/TipoEquipamentosServController';
import MarcaVeiculosController from '@/controllers/MarcaVeiculosController';
import ModeloVeiculosController from '@/controllers/ModeloVeiculosController';
import AgendamentoVeiculosController from '@/controllers/AgendamentoVeiculosController';
import PedidosController from '@/controllers/PedidosController';
import CondicionalController from '@/controllers/CondicionalController';
import AtualizaBancoController from '@/controllers/AtualizaBancosController';
import BarrisController from '@/controllers/projetos/gauchinho/BarrisController';
import CarregamentoBarrisController from '@/controllers/projetos/gauchinho/CarregamentoBarrisController';
import CarregamentoBarrisPController from '@/controllers/projetos/gauchinho/CarregamentoBarrisPController';
import MovimentacaoBarrisController from '@/controllers/projetos/gauchinho/MovimentacaoBarrisController';
import ConsultaProdutosController from '@/controllers/ConsultaProdutosController';
import SolicitacoesCalendarioController from '@/controllers/projetos/helpDesk/SolicitacoesCalendarioController';
import RelatoriosController from '@/controllers/relatorios/RelatoriosController';
import RamoDeAtividadesController from '@/controllers/RamoDeAtividadesController';
import TributacoesController from '@/controllers/TributacoesController';
import RequisicoesController from '@/controllers/requisicoes/RequisicoesController';
import RequisicoesProdutoController from '@/controllers/requisicoes/RequisicoesProdutoController';
import ObrasController from '@/controllers/ObrasController';
import SaldoEstoqueController from '@/controllers/SaldoEstoqueController';
import WhatsappController from '@/controllers/whatsapp/WhatsappController';
import EmailController from '@/controllers/email/EmailController';
import OrdemServicosServicosController from '@/controllers/OrdemServicosServicosController';
import ContasAReceberController from '@/controllers/contasAReceber/ContasAReceberController';
import PedidoFatController from '@/controllers/pedidoFat/PedidoFatController';
import BoletosController from '@/controllers/boletos/BoletosController';

const routes = {
    EmpresaController,
    VeiculosController,
    EstadosController,
    CidadesController,
    LoginController,
    OrdersController,
    OrderSetorController,
    ClientesController,
    EquipamentosController,
    CondicaoPagamentoController,
    ServicosController,
    PecasServicesController,
    UploadController,
    ColaboradoresController,
    ProdutoController,
    ParametrosGenericosController,
    OrderServicoMecanicoController,
    CorrecaoEstoqueController,
    GruposController,
    SubGruposController,
    NcmController,
    MarcaController,
    ObservacoesController,
    FornecedoresController,
    OrcamentoController,
    OrcamentoServController,
    OrcamentoProdController,
    OrcamentoGerador,
    TiposServicoController,
    SetoresServicoController,
    LocaisController,
    UnidadesController,
    MarcaProdutosController,
    FormasPagamentoController,
    EmpresasController,
    PaisesController,
    NaturezasOperacaoController,
    FuncoesController,
    CondicoesVencimentoController,
    BancosController,
    CoresController,
    NumeracoesController,
    TamanhosController,
    ProgramasController,
    UsuariosController,
    ClientesHistoricoController,
    ProdutosEspeciaisController,
    ContratosClientesController,
    ContratosEquipamentoController,
    ContratoClientesController,
    GruposHistoricoPadraoController,
    GruposServicoController,
    HistoricosController,
    HistoricoPadraoController,
    ClientesFamiliarController,
    ClientesServicosController,
    ClientesVendasController,
    SolicitacaoController,
    OrdemPecasController,
    OrdemPecasProdController,
    OrdemPecasServController,
    AgendamentoServicoController,
    ModeloEquipamentosController,
    TipoEquipamentosController,
    MarcaEquipamentosController,
    TipoEquipamentosServController,
    MarcaVeiculosController,
    ModeloVeiculosController,
    AgendamentoVeiculosController,
    PedidosController,
    CondicionalController,
    AtualizaBancoController,
    BarrisController,
    CarregamentoBarrisController,
    CarregamentoBarrisPController,
    MovimentacaoBarrisController,
    ConsultaProdutosController,
    SolicitacoesCalendarioController,
    RelatoriosController,
    RamoDeAtividadesController,
    TributacoesController,
    RequisicoesController,
    RequisicoesProdutoController,
    ObrasController,
    SaldoEstoqueController,
    WhatsappController,
    EmailController,
    OrdemServicosServicosController,
    ContasAReceberController,
    PedidoFatController,
    BoletosController
}

export default routes;
