import api from '../config/api';
import { func } from '../functions/functions';

class OrdemPecasController {
  
  // async getLength(req, res){
  //   let total = await api.post('/ordempecas/registros/1', req);
  //   let response = res;
  //   response.total = total
  //   return response.data;
  // }

    async index(req) {
        let response = await api.post(`/ordempecas/filtro`, req);
        response?.data.forEach((item, index) => {
          item.DATA_EMISSAO = func.convertDateTime(item.ORPE_EMISSAO) 
          item.DATA_PREVISAO = func.convertDateTime(item.ORPE_PREVISAO) 
          item.ORDEM = index % 2 === 0 ? 0 : 1;
        });
        const result = response?.data || response;
        return result ;
    }

    async save(req) {
        let response = await api.post('/ordempecas', req);
        return response.data;
    }

    async update(req) {
      let response = await api.put(`/ordempecas/${req.ORPE_ID}`, req);
      return response.data;
    }

    async delete(req) {
      let response = await api.delete(`/ordempecas/${req.ID}/${req.EMP_ID}`);
      return response.data;
    }
    async getImagens(req) {
      let response = await api.post(`/ordempecasimg/${req.ORPE_ID}`, req);
      return response.data;
    }
    async imprimir(req){
      const { id, USR_USUARIO } = req;
      if(id) {
       const response = await this.index({ORPE_ID: req.id});
       req = response.find(item => item.ORPE_ID === req?.id);
       req.USR_USUARIO = USR_USUARIO;
      }
      const {
        ORPE_ID,
        ORPE_FIL_ID,
        ORPE_EMISSAO,
        ORPE_CLI_EMP_ID,
        ORPE_CLI_ID,
        ORPE_SITUACAO
      } = req;
      const form = {
        ORPE_ID,
        ORPE_FIL_ID,
        ORPE_EMISSAO,
        ORPE_CLI_EMP_ID,
        ORPE_CLI_ID,
        ORPE_SITUACAO
      };
      form.ORPE_EMISSAO = form.ORPE_EMISSAO.split("T")[0];
      form.ORPE_USR_ID = USR_USUARIO;
      form.ORPE_IMPRIMIR = 'S'
      try {
        return await this.update(form);
      } catch (error) {
        return error;
      }
    }

    async deleteImagem(req) {
      const { ORPF_ID, ORPF_IMG_1, ORPF_ORPE_ID } = req;
      let response = await api.delete(`/ordempecasimg/${ORPF_ORPE_ID}/${ORPF_ID}/${ORPF_IMG_1}`, req);
      return response.data;
    }

    async upload(data){
      var formData = new FormData();
      formData.append("file", data.IMAGE);
      formData.append("ORPF_ORPE_ID", data.ORPE_ID);
      formData.append("USR_USUARIO", data.USR_USUARIO);
      return api.post(`/ordempecasimg`, formData, {
          headers: {
              "Content-Type": "multipart/form-data"
          },
      });
  }
}
export default new OrdemPecasController();