import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
// import Dashboard from '../views/Dashboard.vue'
import store from '../store/store'
import api from '../config/api'
import api_rel from '../config/api_rel'
import { cript } from '../functions/criptografia'

Vue.use(VueRouter)

import notFoundRoutes from './routes/notFoundRoutes'

import TestesRoutes from "./routes/teste/TestesRoutes"

import authenticationRoutes from './routes/authenticationRoutes';
import dashboardRoutes from './routes/dashboardRoutes'
import cidadesRoutes from './routes/cidadesRoutes';
import reservaVeiculosRoutes from './routes/reservaVeiculosRoutes';
import clientesRoutes from './routes/clientesRoutes';
import ordemServico from './routes/ordemServicoRoutes';
import whatsappRoutes from './routes/whatsappRoutes';
import equipamentosRoutes from './routes/equipamentoRoutes';
import marcaEquipamentoRoutes from './routes/marcaEquipamentoRoutes';
import modeloEquipamentoRoutes from './routes/modeloEquipamentoRoutes';
import tipoEquipamentoRoutes from './routes/tipoEquipamentoRoutes';
import veiculosRoutes from './routes/veiculosRoutes'
import marcaVeiculoRoutes from './routes/marcaVeiculoRoutes'
import modeloVeiculoRoutes from './routes/modeloVeiculoRoutes'
import ordemVeiculoRoutes from './routes/ordemVeiculoRoutes'
import correcaoEstoqueRoute from './routes/correcaoEstoqueRoutes'
import observacoesRoutes from './routes/observacoesRoutes'
import fornecedoresRoutes from './routes/fornecedoresRoutes'
import orcamentoRoutes from './routes/orcamentoRoutes'
import tiposServicoRoutes from './routes/tiposServicoRoutes'
import setoresServicoRoutes from './routes/setoresServicoRoutes'
import locaisRoutes from './routes/locaisRoutes'
import gruposRoutes from './routes/gruposRoutes'
import unidadesRoutes from './routes/unidadesRoutes'
import marcaProdutoRoutes from './routes/marcaProdutoRoutes'
import subGruposRoutes from './routes/subGruposRoutes'
import formasPagamentoRoutes from './routes/formasPagamentoRoutes'
import empresasRoutes from './routes/empresasRoutes'
import paisesRoutes from './routes/paisesRoutes'
import estadosRoutes from './routes/estadosRoutes'
import naturezasOperacao from './routes/naturezasOperacaoRoutes'
import funcoesRoutes from './routes/funcoesRoutes'
import colaboradoresRoutes from './routes/colaboradoresRoutes'
import condicoesVencimentoRoutes from './routes/condicoesVencimentoRoutes'
import conexaoLocalRoutes from './routes/conexaoLocalRoutes'
import bancosRoutes from './routes/bancosRoutes'
import servicosRoutes from './routes/servicosRoutes'
import coresRoutes from './routes/coresRoutes'
import numeracoesRoutes from './routes/numeracoesRoutes'
import tamanhosRoutes from './routes/tamanhosRoutes'
import inteligenciaArtificial from './routes/inteligenciaArtificialRoutes'
import programasRoutes from './routes/programasRoutes'
import usuariosRoutes from './routes/usuariosRoutes'
import gruposHistoricoPadraoRoutes from './routes/gruposHistoricoPadraoRoutes'
import gruposServicoRoutes from './routes/gruposServicoRoutes'
import historicosRoutes from './routes/historicosRoutes'
import historicoPadraoRoutes from './routes/historicoPadraoRoutes'
import solicitacoesRoutes from './routes/solicitacoesRoutes'
import setorSolicitacoesRoutes from './routes/setorSolicitacoesRoutes'
import situacaoSolicitacoesRoutes from './routes/situacaoSolicitacoesRoutes'
import projetoSolicitacoesRoutes from './routes/projetoSolicitacoesRoutes'
import controleServicosRoutes from './routes/controleServicosRoutes'
import produtosRoutes from './routes/produtosRoutes'
import ncmRoutes from './routes/ncmRoutes'
import pedidosRoutes from './routes/pedidosRoutes'
import condicionalRoutes from './routes/condicionalRoutes'
import atualizaBancoRoutes from './routes/AtualizaBancoRoutes'
import barrisRoutes from './routes/projetos/gauchinho/barrisRoutes'
import carregamentoBarrisRoutes from './routes/projetos/gauchinho/carregamentoBarrisRoutes'
import carregamentoBarrisPRoutes from './routes/projetos/gauchinho/carregamentoBarrisPRoutes'
import consultaProdutosRoutes from './routes/consultaProdutosRoutes'
import relatoriosRoutes from './routes/relatorios/relatoriosRoutes'
import TributacoesRoutes from './routes/TributacoesRoutes'
import RequisicoesRoutes from './routes/RequisicoesRoutes'
import ContasAReceberRoutes from './routes/ContasAReceberRoutes'

const routes = [
  ...notFoundRoutes,
  ...TestesRoutes,
  ...conexaoLocalRoutes,
  ...authenticationRoutes,
  ...dashboardRoutes,
  ...correcaoEstoqueRoute,
  ...cidadesRoutes,
  ...clientesRoutes,
  ...equipamentosRoutes,
  ...marcaEquipamentoRoutes,
  ...modeloEquipamentoRoutes,
  ...tipoEquipamentoRoutes,
  ...reservaVeiculosRoutes,
  ...ordemServico,
  ...ordemVeiculoRoutes,
  ...marcaVeiculoRoutes,
  ...modeloVeiculoRoutes,
  ...veiculosRoutes,
  ...whatsappRoutes,
  ...observacoesRoutes,
  ...fornecedoresRoutes,
  ...orcamentoRoutes,
  ...tiposServicoRoutes,
  ...setoresServicoRoutes,
  ...locaisRoutes,
  ...gruposRoutes,
  ...unidadesRoutes,
  ...marcaProdutoRoutes,
  ...subGruposRoutes,
  ...formasPagamentoRoutes,
  ...empresasRoutes,
  ...paisesRoutes,
  ...estadosRoutes,
  ...naturezasOperacao,
  ...funcoesRoutes,
  ...colaboradoresRoutes,
  ...condicoesVencimentoRoutes,
  ...bancosRoutes,
  ...servicosRoutes,
  ...coresRoutes,
  ...numeracoesRoutes,
  ...tamanhosRoutes,
  ...inteligenciaArtificial,
  ...programasRoutes,
  ...usuariosRoutes,
  ...gruposHistoricoPadraoRoutes,
  ...gruposServicoRoutes,
  ...historicosRoutes,
  ...historicoPadraoRoutes,
  ...solicitacoesRoutes,
  ...setorSolicitacoesRoutes,
  ...situacaoSolicitacoesRoutes,
  ...projetoSolicitacoesRoutes,
  ...controleServicosRoutes,
  ...produtosRoutes,
  ...ncmRoutes,
  ...pedidosRoutes,
  ...condicionalRoutes,
  ...atualizaBancoRoutes,
  ...barrisRoutes,
  ...carregamentoBarrisRoutes,
  ...carregamentoBarrisPRoutes,
  ...consultaProdutosRoutes,
  ...relatoriosRoutes,
  ...TributacoesRoutes,
  ...RequisicoesRoutes,
  ...ContasAReceberRoutes,
];
const router = new VueRouter({
  mode: 'history',
  routes
});
router.beforeEach((to, from, next) => {
  let token = "";
  document.title = to.meta.title || 'BSSoft'
  const isFavourite = store?.state.favoritos.find(item => item.PROG_DIR_WEB == to.path);
  let itemAtual = routes.find(item => item.path == to.path);
  if (itemAtual?.meta) {
    itemAtual.meta.FAV = isFavourite ? true : false;
  }

  if (VueCookies.get('token')) {
    store.dispatch('saveToken', VueCookies.get('token'));
    token = cript.decryptData(store.state.token);
  }
  if (VueCookies.get('id')) {
    store.dispatch('saveID', VueCookies.get('id'));
    api.defaults.headers.common["clienteid"] = cript.decryptData(store.state.id);
    api_rel.defaults.headers.common["clienteid"] = cript.decryptData(store.state.id);
  }
  if (VueCookies.get('user')) {
    store.dispatch('saveUser', VueCookies.get('user'));
  }
  if (VueCookies.get('empresa')) {
    store.dispatch('saveEmpresa', VueCookies.get('empresa'));
  }
  store.commit('setNotSaved', false);
  if (to.matched.some(record => record.meta.requiresAuth)) {

    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    api_rel.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    // Verifica se o usuário está autenticado usando o getter 'isAuthenticated'
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      window.scrollTo(0, 0)
      next();
    }
  } else {
    window.scrollTo(0, 0)
    next();
  }
});

export default router