import api from '../config/api';
class GruposServicoController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post(`/gruposervico/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/gruposervico/${req.EMP_ID}`, req);
      if(!response.data.msg){
        response.data.forEach((item, index) => {
          item["ORDEM"] = index % 2 === 0 ? 0 : 1;
          item.id = item.GRPS_ID;
          item.text = item.GRPS_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async create(req) {
    try {
      let response = await api.post('/gruposervico', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async update(req) {
    try {
      let response = await api.put(`/gruposervico/${req.GRPS_ID}/${req.GRPS_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/gruposervico/${req.GRPS_ID}/${req.GRPS_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/gruposervico/pesquisar/${req.GRPS_ID}/${req.GRPS_EMP_ID}`);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.GRPS_ID;
          item.text = item.GRPS_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new GruposServicoController();