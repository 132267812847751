import api from '../../config/api';
import { func } from '../../functions/functions';

class ContratosClientesController {
  
  async getLength(req, res){
    let total = await api.post(`/contratoscliente/registros/${req.CONT_CLI_ID}/${req.CONT_CLI_EMP_ID}`, req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }

    async index(req) {
        let response = await api.post(`/contratoscliente/${req.CONT_CLI_ID}/${req.CONT_CLI_EMP_ID}`, req);
        if(response?.data){
          response?.data.forEach((item, index) => {
            item.CONT_VALOR_FORMATED = func.convertMoney(item.CONT_VALOR);
            item.CONT_DATA_INI_FORMATED = func.convertDateTime(item.CONT_DATA_INI);
            item.CONT_DATA_FIM_FORMATED = func.convertDateTime(item.CONT_DATA_FIM);
            item.ORDEM = index % 2 === 0 ? 0 : 1;
          });
          const result = response?.data || response;
          return await this.getLength(req, result);
        }
    }

    async save(req) {
        let response = await api.post('/contratoscliente', req);
        return response?.data || response;
    }

    async update(req) {
      let response = await api.put(`/contratoscliente/${req.CONT_CLI_ID}/${req.CONT_CLI_EMP_ID}/${req.CONT_ID}`, req);
      return response?.data || response;
    }

    async delete(req) {
      let response = await api.delete(`/contratoscliente/${req.CONT_CLI_ID}/${req.CONT_CLI_EMP_ID}/${req.CONT_ID}`);
      return response?.data || response;
    }

}
export default new ContratosClientesController();