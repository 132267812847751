import api from '../../config/api';

class ContratoClientesController {

  async getLength(req, res) {
    let total = await api.post(`/contratocliente/registros/${req.CONC_CLI_ID}/${req.CONC_CLI_EMP_ID}`, req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }
  async index(req) {
    let response = await api.post(`/contratocliente/${req.CONC_CLI_ID}/${req.CONC_CLI_EMP_ID}`, req);
    if(response.data.length > 0){
      response?.data?.forEach((item, index) => {
        item["ORDEM"] = index % 2 === 0 ? 0 : 1;
      });
    }
    const result = response?.data || response;
    return await this.getLength(req, result);
  }

  async save(req) {
    let response = await api.post('/contratocliente', req);
    return response?.data || response;
  }

  async update(req) {
    let response = await api.put(`/contratocliente/${req.CONC_CLI_ID}/${req.CONC_CLI_EMP_ID}/${req.CONC_ID}`, req);
    return response?.data || response;
  }

  async delete(req) {
    let response = await api.delete(`/contratocliente/${req.CONC_CLI_ID}/${req.CONC_CLI_EMP_ID}/${req.CONC_ID}`);
    return response?.data || response;
  }

}
export default new ContratoClientesController();