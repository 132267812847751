import api from '../../config/api';
import { func } from '../../functions/functions';
class ClientesVendasController {
  async index(req) {
    let response = await api.post(`/clientesvendas/${req.PEDF_CLI_ID}/${req.PEDF_CLI_EMP_ID}`, req);
    if(response?.data?.code != 500){
      response?.data?.forEach((element, index) => {
        element.ORDEM = index % 2 === 0 ? 0 : 1;
        element.PEDF_DTA_EMISSAO_FORMATED = func.convertDateTime(element.PEDF_DTA_EMISSAO);
        element.PEDF_DTA_ENTRADA_FORMATED = func.convertDateTime(element.PEDF_DTA_ENTRADA);
        element.PEDF_TOT_PROD_FORMATED = func.convertMoney(element.PEDF_TOT_PROD);
        element.PEDF_TOT_SERV_FORMATED = func.convertMoney(element.PEDF_TOT_SERV);
        element.PEDF_VLR_ACRE_FORMATED = func.convertMoney(element.PEDF_VLR_ACRE);
        element.PEDF_VLR_FRETE_FORMATED = func.convertMoney(element.PEDF_VLR_FRETE);
        element.PEDF_VLR_TOTAL_FORMATED = func.convertMoney(element.PEDF_VLR_TOTAL);
        element.PEDF_VLR_DESC_FORMATED = func.convertMoney(element.PEDF_VLR_DESC);
      });
    }
    return response?.data;
  }
  async indexProdutos(req) {
    let response = await api.post(`/clientesvendas/produtos/${req.PEDF_ID}/${req.PEDF_EMP_ID}/${req.PEDF_SERIE}`, req);
    if(response?.data?.code != 500){
      response?.data?.forEach((element, index) => {
        element.ORDEM = index % 2 === 0 ? 0 : 1;
        element.PEDI_VLR_UNITARIO_FORMATED = func.convertMoney(element.PEDI_VLR_UNITARIO);
        element.PEDI_VLR_ACRECIMO_FORMATED = func.convertMoney(element.PEDI_VLR_ACRECIMO);
        element.PEDI_VLR_DESCONTO_FORMATED = func.convertMoney(element.PEDI_VLR_DESCONTO);
        element.PEDI_VLR_FRETE_FORMATED = func.convertMoney(element.PEDI_VLR_FRETE);
        element.PEDI_VLR_TOTAL_FORMATED = func.convertMoney(element.PEDI_VLR_TOTAL);
      });
    }
    return response?.data;
  }
  async indexServicos(req) {
    let response = await api.post(`/clientesvendas/servicos/${req.PEDF_ID}/${req.PEDF_EMP_ID}/${req.PEDF_SERIE}`, req);
    if(response?.data?.code != 500){
      response.data.forEach((element, index) => {
        element.ORDEM = index % 2 === 0 ? 0 : 1;
        element.PFOS_QTDE_FORMATED = func.convertMoney2(element.PFOS_QTDE);
        element.PFOS_VLR_SERV_FORMATED = func.convertMoney(element.PFOS_VLR_SERV);
        element.PFOS_VLR_UNIT_FORMATED = func.convertMoney(element.PFOS_VLR_UNIT);
      });
    }
    return response?.data;
  }
}
export default new ClientesVendasController();