import api from '../config/api';

class TipoEquipamentosServController {

  async index(req) {
    const response = await api.post(`/tipoequipamentoserv/${req.ID}`);
    if(response.data.length > 0){
      response?.data?.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
      });
    }
    const result = response?.data || response;
    return result;
  }

  async save(req) {
    const response = await api.post('/tipoequipamentoserv', req);
    return response.data;
  }

  async edit(req) {
     const response = await api.put(`/tipoequipamentoserv/${req.TEQS_TPEQ_ID}/${req.ID}`, req);
    return response.data;
  }

  async delete(req) {
    const response = await api.delete(`/tipoequipamentoserv/${req.TPEQ_ID}/${req.ID}`);
    return response;
  }
}

export default new TipoEquipamentosServController();