const historicoPadraoRoutes = [
  {
    path: '/historicopadrao',
    name: 'IndexHistoricoPadrao',
    meta: {
      requiresAuth: true,
      title: "Centro de Custo",
    },
    component: () => import('../../views/historicoPadrao/IndexHistoricoPadrao.vue')
  },
  {
    path: '/historicopadrao/inserir',
    name: 'InsertHistoricoPadrao',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Centro de Custo",
    },
    component: () => import('../../views/historicoPadrao/InsertHistoricoPadrao.vue')
  },
  {
    path: '/historicopadrao/alterar',
    name: 'AlterHistoricoPadrao',
    meta: {
      requiresAuth: true,
      title: "Editar Centro de Custo",
    },
    component: () => import('../../views/historicoPadrao/AlterHistoricoPadrao.vue')
  },
]
export default historicoPadraoRoutes;