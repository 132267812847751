import api from '../config/api';

class CorrecaoEstoqueController {
    async index(req) {
        let response = await api.post(`/correcaoestoque/${req.EMP_ID}`, req);
        const result = response?.data || response;
        return result;
    }
    async save(req) {
        let response = await api.post('/correcaoestoque/', req);
        return response.data;
    }
    async edit(req) {
        let response = await api.put(`/correcaoestoque/${req.ID}/${req.EMP_ID}`, req);
        return response.data;
    }
    async delete(req) {
        let response = await api.delete(`/correcaoestoque/${req.ID}/${req.EMP_ID}`);
        return response.data
    }
    //estoque
    async estoqueAlocado(req) {
        let response = await api.get(`/produtosalocados/${req.ID}/${req.EMP_ID}`);
        return response.data
    }
    async getMateriaPrima(req) {
        let response = await api.post(`/materiaprima/${req.PROD_ID}/${req.EMP_ID}`);
        return response.data
    }


}
export default new CorrecaoEstoqueController();