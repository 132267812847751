const TestesRoutes = [
  {
    path: "/testes/indexForm",
    name: "DataTableIndexComponent",
    meta: {
      requiresAuth: true,
      title: "Inserir Atualização ao Banco de Dados",
    },
    component: () => import("@/components/geral/DataTableIndexComponent.vue")
  },
]
export default TestesRoutes;
