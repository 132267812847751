const paisesRoutes =[
  {
    path: '/paises',
    name: 'IndexPaises',
    meta: {
      requiresAuth: true,
      title: "Países",
    },
    component: () => import('../../views/paises/IndexPaises.vue')
  },
  {
    path: '/paises/inserir',
    name: 'InsertPaises',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Países",
    },
    component: () => import('../../views/paises/InsertPaises.vue')
  },
  {
    path: '/paises/alterar',
    name: 'AlterPaises',
    meta: {
      requiresAuth: true,
      title: "Editar País",
    },
    component: () => import('../../views/paises/AlterPaises.vue')
  },
]
export default paisesRoutes;