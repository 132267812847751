const tamanhosRoutes = [
  {
    path: '/tamanhos',
    name: 'IndexTamanhos',
    meta: {
      requiresAuth: true,
      title: "Tamanhos",
    },
    component: () => import('../../views/tamanhos/IndexTamanhos.vue')
  },
  {
    path: '/tamanhos/inserir',
    name: 'InsertTamanhos',
    meta: {
      requiresAuth: true,
      title: "Cadastro de tamanhos",
    },
    component: () => import('../../views/tamanhos/InsertTamanhos.vue')
  },
  {
    path: '/tamanhos/alterar',
    name: 'AlterTamanhos',
    meta: {
      requiresAuth: true,
      title: "Editar tamanho",
    },
    component: () => import('../../views/tamanhos/AlterTamanhos.vue')
  },
];
export default tamanhosRoutes;