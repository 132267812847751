const gruposServicoRoutes = [
  {
    path: '/gruposservico',
    name: 'IndexGruposServico',
    meta: {
      requiresAuth: true,
      title: "Grupos de Serviço",
    },
    component: () => import('../../views/gruposServico/IndexGruposServico.vue')
  },
  {
    path: '/gruposservico/inserir',
    name: 'InsertGruposServico',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Grupos de Serviço",
    },
    component: () => import('../../views/gruposServico/ManageGruposServico.vue')
  },
  {
    path: '/gruposservico/alterar',
    name: 'AlterGruposServico',
    meta: {
      requiresAuth: true,
      title: "Editar Grupo de Serviço",
    },
    component: () => import('../../views/gruposServico/ManageGruposServico.vue')
  },
]
export default gruposServicoRoutes;