const colaboradoresRoutes = [
  {
    path: '/colaboradores',
    name: 'IndexColaboradores',
    meta: {
      requiresAuth: true,
      title: "Colaboradores",
    },
    component: () => import('../../views/colaboradores/IndexColaboradores.vue')
  },
  {
    path: '/colaboradores/inserir',
    name: 'InsertColaboradores',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Colaboradores",
    },
    component: () => import('../../views/colaboradores/InsertColaboradores.vue')
  },
  {
    path: '/colaboradores/alterar',
    name: 'AlterColaboradores',
    meta: {
      requiresAuth: true,
      title: "Editar Colaborador",
    },
    component: () => import('../../views/colaboradores/AlterColaboradores.vue')
  },
];
export default colaboradoresRoutes;