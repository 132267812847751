const authenticationRoutes = [
    {
      path: '/login',
      name: 'login',
      component: () => import('../../views/authentication/LoginForm.vue'),
      meta: {
        hideNavbar: true,
      }
    },
    // ... outras rotas de autenticação
  ];
  
  export default authenticationRoutes;
  