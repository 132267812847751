import api from '../config/api';
// import { func } from '../functions/functions';

class OrcamentoServController {
  async index(req) {
    let response = await api.post(`/orcamentoservico/${req.ORCA_ID}/${req.EMP_ID}/OS`);
    return response.data;
  }
  async save(req) {
    let response = await api.post('/orcamentoservico', req);
    return response.data;
  }
  async edit(req) {
    let response = await api.put(`/orcamentoservico/${req.ORCI_ORCA_ID}/${req.EMP_ID}/OS/${req.ORCI_ID}`, req);
    return response.data;
  }
  async delete(req) {
    let response = await api.delete(`/orcamentoservico/${req.ORCA_ID}/${req.EMP_ID}/OS/${req.ORCI_ID}`);
    return response.data;
  }
}
export default new OrcamentoServController();