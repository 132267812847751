const programasRoutes = [
  {
    path: '/programas',
    name: 'IndexProgramas',
    meta: {
      requiresAuth: true,
      title: "Programas",
    },
    component: () => import('../../views/programas/IndexProgramas.vue')
  },
  {
    path: '/programas/inserir',
    name: 'InsertProgramas',
    meta: {
      requiresAuth: true,
      title: "Cadastro de programas",
    },
    component: () => import('../../views/programas/InsertProgramas.vue')
  },
  {
    path: '/programas/alterar',
    name: 'AlterProgramas',
    meta: {
      requiresAuth: true,
      title: "Editar programa",
    },
    component: () => import('../../views/programas/AlterProgramas.vue')
  },
]
export default programasRoutes;