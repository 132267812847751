import api from '../config/api';
class ObrasController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( `/obras/registros`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      let response = await api.post( `/obras/filtro`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.OBRA_ID;
          item.text = item.OBRA_DESCRICAO;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async created ( req ) {
    try {
      let response = await api.post( '/obras', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updated ( req ) {
    try {
      let response = await api.put( `/obras/${ req.OBRA_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async delete ( req ) {
    try {
      let response = await api.delete( `/obras/${ req.OBRA_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async search ( req ) {
    try {
      let response = await api.get( `/obras/pesquisar/${ req.OBRA_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async view ( req ) {
    try {
      let response = await api.get( `/obras/${ req.OBRA_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new ObrasController();