const cidadesRoutes = [
  {
    path: '/cidades',
    name: 'IndexCidades',
    meta: {
      requiresAuth: true,
      title: "Cidades",
      subheader: [
        { text: 'Inicio', route: "/" },
        { text: 'Cidades', route: "/cidades" },
      ],
      buttons: [
        { text: "", icon: "mdi-plus", route: "/cidades/inserir", color: "#21c1d6" },
        { text: "", icon: "mdi-printer-outline", route: "/cidades/relatorios", color: "purple" },
      ],
    },
    component: () => import('../../views/cidades/IndexCidades.vue')
  },
  {
    path: '/cidades/inserir',
    name: 'InsertCidades',
    meta: {
      requiresAuth: true,
      title: "Cadastrar Cidades",
      subheader: [
        { text: "Inicio", route: "/" },
        { text: "Cidades", route: "/cidades" },
        { text: "Cadastro", route: "/cidades/inserir" },
      ],
    },
    component: () => import('../../views/cidades/InsertCidades.vue')
  },
  {
    path: '/cidades/alterar',
    name: 'EditCidades',
    meta: {
      requiresAuth: true,
      title: "Editar Cidade",
      subheader: [
        { text: "Inicio", route: "/" },
        { text: "Cidades", route: "/cidades" },
        { text: "Edição", route: "/cidades/alterar" },
      ],
    },
    component: () => import('../../views/cidades/AlterCidades.vue')
  },
];
export default cidadesRoutes;
  