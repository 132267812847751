const empresasRoutes = [
  {
    path: '/empresas',
    name: 'IndexEmpresas',
    meta: {
      requiresAuth: true,
      title: "Empresas",
    },
    component: () => import('../../views/empresas/IndexEmpresas.vue')
  },
  {
    path: '/empresas/inserir',
    name: 'InsertEmpresas',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Empresas",
    },
    component: () => import('../../views/empresas/InsertEmpresas.vue')
  },
  {
    path: '/empresas/alterar',
    name: 'AlterEmpresas',
    meta: {
      requiresAuth: true,
      title: "Editar Empresa",
    },
    component: () => import('../../views/empresas/AlterEmpresas.vue')
  },
]
export default empresasRoutes;