
import api from '@/config/api';
class PedidoFatController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/pedido/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      const response = await api.post( `/pedido/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.CRCB_ID;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async create ( req ) {
    try {
      const response = await api.post( '/pedido', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async update ( req ) {
    try {
      const response = await api.put( `/pedido/${ req.PEDF_ID }/${ req.PEDF_EMP_ID }/${ req.PEDF_SERIE }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async delete ( req ) {
    try {
      const response = await api.delete( `/pedido/${ req.PEDF_ID }/${ req.PEDF_EMP_ID }/${ req.PEDF_SERIE }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updateGenerator ( req ) {
    try {
      const response = await api.put( `/pedidogenerator/${ req.PEDF_ID }/${ req.PEDF_EMP_ID }/${ req.PEDF_SERIE }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async duplicar ( req ) {
    try {
      const response = await api.post( `/pedidoduplicar/${ req.PEDF_ID }/${ req.PEDF_EMP_ID }/${ req.PEDF_SERIE }/${ req.PEDF_USR_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new PedidoFatController();