import api from '../config/api';
class FornecedoresController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post('/fornecedor/registros', req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/fornecedor/filtro`, req);
      if(!response.data.msg){
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.FORN_ID;
          item.text = item.FORN_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/fornecedor/', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }    
  async updated(req) {
    try {
      let response = await api.put(`/fornecedor/${req.FORN_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/fornecedor/${req.FORN_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/fornecedor/pesquisar/${req.FORN_ID}`,);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.FORN_ID;
          item.text = item.FORN_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
  // transformar em cliente
  async transformarCliente(req) {
    try {
      let response = await api.get(`/fornecedor/cliente/${req.FOPR_ID}/${req.FOPR_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  // fornecedor produto
  async produto(req){
    try {
      let response = await api.post(`/fornecedorprod/${req.FORN_ID}`)
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async createdProduto(req) {
    try {
      let response = await api.post('/fornecedorprod', req);
      return response.data;
    } catch(error) {
      throw error
    } 
  }
  async updatedProduto(req) {
    try {
      let response = await api.put(`/fornecedorprod/${req.FOPR_FORN_ID}/${req.FOPR_PROD_ID}/${req.FOPR_PROD_EMP_ID}/${req.FOPR_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteProduto(req) {
    try {
      let response = await api.delete(`/fornecedorprod/${req.FOPR_FORN_ID}/${req.FOPR_PROD_ID}/${req.FOPR_PROD_EMP_ID}/${req.FOPR_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new FornecedoresController();