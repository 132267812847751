const TributacoesRoutes = [
  {
    path: '/tributacoes/inserir',
    name: 'InsertTributacoes',
    meta: {
      requiresAuth: true,
      title: "Cadastro de ICMS",
    },
    component: () => import('../../views/tributacoes/ManageTributacoes.vue')
  },
  {
    path: '/tributacoes/alterar',
    name: 'AlterTributacoes',
    meta: {
      requiresAuth: true,
      title: "Alterar ICMS",
    },
    component: () => import('../../views/tributacoes/ManageTributacoes.vue')
  },
  {
    path: '/tributacoes',
    name: 'IndexTributacoes',
    meta: {
      requiresAuth: true,
      title: "ICMS",
    },
    component: () => import('../../views/tributacoes/IndexTributacoes.vue')
  }
]
export default TributacoesRoutes;