import api from '../config/api';

class ProdutosEspeciaisController {
  
    async index(req) {
        let response = await api.post(`/produtosespeciais/${req.PRES_CLI_ID}/${req.PRES_EMP_ID}`, req);
        return response.data;
    }

    async save(req) {
        let response = await api.post('/produtosespeciais', req);
        return response.data;
    }

    async update(req) {
      let response = await api.put(`/produtosespeciais/${req.PRES_CLI_ID}/${req.PRES_CLI_EMP_ID}/${req.PRES_PROD_ID}`, req);
      return response.data;
    }
    async delete(req) {
      let response = await api.delete(`/produtosespeciais/${req.PRES_CLI_ID}/${req.PRES_CLI_EMP_ID}/${req.PRES_PROD_ID}`);
      return response.data;
    }
}
export default new ProdutosEspeciaisController();