const pedidosRoutes = [
    {
        path: '/pedidos',
        name: 'IndexPedidos',
        meta: {
          requiresAuth: true,
          title: "Pedidos",
        },
        component: () => import('../../views/pedidos/IndexPedidos.vue')
      },
      {
        path: '/pedidos/inserir',
        name: 'InsertPedido',
        meta: {
          requiresAuth: true,
          title: "Cadastro de Pedido",
        },
        component: () => import('../../views/pedidos/PedidosView.vue')
      },
      {
        path: '/pedidos/alterar',
        name: 'AlterPedido',
        meta: {
          requiresAuth: true,
          title: "Editar Pedidos",
        },
        component: () => import('../../views/pedidos/PedidosView.vue')
      },
]
export default pedidosRoutes;