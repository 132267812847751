import api from '../config/api';
import { func } from '../functions/functions';

class PedidosController {

    async getLength(req, res) {
        let total = await api.post(`/pedido/registros/${req.PEDF_EMP_ID}`, req);
        let response = res;
        response.total = total.data[0].TOT_REGISTROS;
        return response;
    }

    async index(req) {

        let response = await api.post(`/pedido/${req.PEDF_EMP_ID}`, req);
        if(response.data.length > 0){
            response?.data?.forEach((item, index) => {
              item.ORDEM = index % 2 === 0 ? 0 : 1;
              item.PEDF_VLR_TOTAL_FORMATED = func.convertMoney(item.PEDF_VLR_TOTAL);
              item.PEDF_DTA_EMISSAO_FORMATED = func.convertDateTime(item.PEDF_DTA_EMISSAO);
              item.PEDF_DTA_CAD_FORMATED = func.convertDateTime(item.PEDF_DTA_CAD);
              item.PEDF_DTA_ENTRADA_FORMATED = func.convertDateTime(item.PEDF_DTA_ENTRADA);
              item.PEDF_HORA_MOV_FORMATED = func.convertTime(item.PEDF_HORA_MOV);
            });
          }
        const result = response?.data || response;
        return await this.getLength(req, result);
    }

    async save(req) {
        let response = await api.post('/pedido', req);
        return response?.data;
    }

    async update(req) {
        let response = await api.put(`/pedido/${req.PEDF_ID}/${req.PEDF_EMP_ID}/${req.PEDF_SERIE}`, req);
        return response?.data;
    }

    async delete(req) {
        let response = await api.delete(`/pedido/${req.PEDF_ID}/${req.PEDF_EMP_ID}/${req.PEDF_SERIE}`);
        return response?.data;
    }
}
export default new PedidosController();