const usuariosRoutes = [
  {
    path: '/usuarios',
    name: 'IndexUsuarios',
    meta: {
      requiresAuth: true,
      title: "Usuários",
    },
    component: () => import('../../views/usuarios/IndexUsuarios.vue')
  },
  {
    path: '/usuarios/inserir',
    name: 'InsertUsuarios',
    meta: {
      requiresAuth: true,
      title: "Cadastro de usuários",
    },
    component: () => import('../../views/usuarios/InsertUsuarios.vue')
  },
  {
    path: '/usuarios/alterar',
    name: 'AlterUsuarios',
    meta: {
      requiresAuth: true,
      title: "Editar usuário",
    },
    component: () => import('../../views/usuarios/AlterUsuarios.vue')
  },
]
export default usuariosRoutes;