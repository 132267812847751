const veiculosRoutes = [
    {
        path: '/veiculos',
        name: 'IndexVeiculo',
        meta: {
            requiresAuth: true,
            title: "Veículos",
        },
        component: () => import('../../views/veiculos/IndexVeiculos.vue')
    },
    {
        path: '/veiculos/inserir',
        name: 'InsertVeiculo',
        meta: {
            requiresAuth: true,
            title: "Cadastrar Veículo",
        },
        component: () => import('../../views/veiculos/InsertVeiculos.vue')
    },
    {
        path: '/veiculos/alterar',
        name: 'AlterVeiculo',
        meta: {
            requiresAuth: true,
            title: "Editar Veículo",
        },
        component: () => import('../../views/veiculos/AlterVeiculos.vue')
    },
]
export default veiculosRoutes;