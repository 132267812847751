const modeloVeiculoRoutes = [
    {
        path: '/modeloveiculo/inserir',
        name: 'InsertModeloVeiculo',
        meta: {
            requiresAuth: true,
            title: "Inserir Modelo Veículo",
        },
        component: () => import('../../views/veiculos/modelo/InsertModeloVeiculo.vue')
    },
    {
        path: '/modeloveiculo/alterar',
        name: 'AlterModeloVeiculo',
        meta: {
            requiresAuth: true,
            title: "Alterar Modelo Veículo",
        },
        component: () => import('../../views/veiculos/modelo/AlterModeloVeiculo.vue')
    },
    {
        path: '/modeloveiculo',
        name: 'IndexModeloVeiculo',
        meta: {
            requiresAuth: true,
            title: "Modelo Veículo",
        },
        component: () => import('../../views/veiculos/modelo/IndexModeloVeiculo.vue')
    },

]
export default modeloVeiculoRoutes;