const fornecedoresRoutes = [
  {
    path: '/fornecedores',
    name: 'IndexFornecedores',
    meta: {
      requiresAuth: true,
      title: "Fornecedores",
    },
    component: () => import('../../views/fornecedores/IndexFornecedores.vue')
  },
  {
    path: '/fornecedores/inserir',
    name: 'InsertFornecedores',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Fornecedores",
    },
    component: () => import('../../views/fornecedores/InsertFornecedores.vue')
  },
  {
    path: '/fornecedores/alterar',
    name: 'AlterFornecedores',
    meta: {
      requiresAuth: true,
      title: "Editar Fornecedor",
    },
    component: () => import('../../views/fornecedores/AlterFornecedores.vue')
  },
]
export default fornecedoresRoutes;