import api from '../config/api';
class EstadosController {
  /* eslint-disable */
  async getLength(req, res) {
    try {
      let total = await api.post(`/estados/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/estados/${req.EMP_ID}`, req);
      if (!response.data.msg) {
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.EST_ID;
          item.text = item.EST_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/estados', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/estados/${req.EST_ID}/${req.EST_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/estados/${req.EST_ID}/${req.EST_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new EstadosController();