import api from '../config/api';

class ContratosEquipamentoController {


  async index(req) {
    try {
      const { CONTE_CONT_ID, CONTE_CONT_EMP_ID } = req;
      const response = await api.post(`/contratoseqpt/${CONTE_CONT_ID}/${CONTE_CONT_EMP_ID}`, req);
      if (response?.data?.code === 500 || !response.data) {
        console.error('Erro na solicitação:', response?.data?.msg);
        return [];
      }
      response?.data?.forEach((item, index) => {
        item["ORDEM"] = index % 2 === 0 ? 0 : 1;
      });
      return response?.data;
    } catch (error) {
      console.error('Erro na solicitação:', error.message);
      return [];
    }
  }

  async save(req) {
    let response = await api.post('/contratoseqpt', req);
    return response.data;
  }

  async update(req) {
    let response = await api.put(`/contratoseqpt/${req.CONTE_CLI_ID}/${req.CONTE_CLI_EMP_ID}/${req.CONTE_ID}`, req);
    return response.data;
  }

  async delete(req) {
    let response = await api.delete(`/contratoseqpt/${req.CONTE_CLI_ID}/${req.CONTE_CLI_EMP_ID}/${req.CONTE_ID}`);
    return response.data;
  }

}
export default new ContratosEquipamentoController();