import api from '../config/api';
import OrdemServicoTotalizacao from './OrdemServicoTotalizacao';

class PecasServicesController {
    async index(req) {
        let response = await api.post(`/ordemservicoproduto/${req.NROS}/${req.EMP_ID}/OS`, req);
        try{
            if (response?.data.length > 0) {
                response?.data?.forEach((item, index) => {
                    item["ORDEM"] = index % 2 === 0 ? 0 : 1;
                });
            }
            const result = response?.data || response;
            return result;
        }
        catch(error){
            console.log(error);
        }
       
    }
    async create(req) {
        let response = await api.post('/ordemservicoproduto/', req);
        await OrdemServicoTotalizacao.calculate({ID: req.ORDP_ORDS_ID, EMP_ID: req.ORDP_ORDS_EMP_ID, SERIE: "OS" });
        return response.data;
    }
    async update(req) {
        let response = await api.put(`/ordemservicoproduto/${req.ORDP_ORDS_ID}/${req.ORDP_ORDS_EMP_ID}/OS/${req.ORDP_SEQ}`, req);
        await OrdemServicoTotalizacao.calculate({ID: req.ORDP_ORDS_ID, EMP_ID: req.ORDP_ORDS_EMP_ID, SERIE: "OS" });
        return response.data;
    }
    async delete(req) {
        let response = await api.delete(`/ordemservicoproduto/${req.ORDP_ORDS_ID}/${req.ORDP_ORDS_EMP_ID}/OS/${req.ORDP_SEQ}`);
        await OrdemServicoTotalizacao.calculate({ID: req.ORDP_ORDS_ID, EMP_ID: req.ORDP_ORDS_EMP_ID, SERIE: "OS" });
        return response.data
    }
    async agrupar(req) {
        let response = await api.put(`/ordemservicoprodutoagrupar/${req.NROS}/${req.EMP_ID}/OS/${req.ORDP_PROD_ID}/${req.EMP_ID}`, req);
        return response.data
    }
    async ordemAcrescimo(req) {
        let response = await api.post(`/ordemservicoacrescimo/${req.NROS}/${req.EMP_ID}/OS`, req);
        return response.data
    }


}
export default new PecasServicesController();