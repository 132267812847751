import api from '@/config/api';
class BoletosController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/boletos/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      const response = await api.post( `/boletos/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.CRCB_ID;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async generate ( req ) {
    // req = {
    //   "boleto_emp_id": 1, /* ID DA EMPRESA */
    //   "bancoId": 4, /* ID DO BANCO, VEM DO NOSSO CADASTRO DE BANCO VINCULADO AO BANCO 748 */
    //   "codigoBeneficiario": "12345", /* CÓDIGO DO SICREDI DE TESTE ??? */
    //   "dataVencimento": "2024-09-15", /* VENCIMENTO DO BOLETO */
    //   "especieDocumento": "DUPLICATA_MERCANTIL_INDICACAO", /* ??? */
    //   "pagador": { /* DADOS DO PAGADOR */
    //     "cep":  "99500000",
    //     "cidade": "",
    //     "documento": "05936574113", /* CPF OU CNPJ */
    //     "nome": "Pedro",
    //     "tipoPessoa": "PESSOA_FISICA",
    //     "endereco": "",
    //     "uf": "RS"
    //   },
    //   "tipoCobranca": "HIBRIDO", /* ??? */
    //   "diasProtestoAuto": null, /* DIAS APÓS O VENCIMENTO PARA PROTESTAR */
    //   "seuNumero": "01", /* NUMERO DE CONTROLE INTERNO */
    //   "valor": "1000.00", /* VALOR DO BOLETO */
    //   "tipoJuros": null, /* VALOR OU PERCENTUAL */
    //   "juros": null, /* VALOR ADICIONAL POR DIA APÓS O VENCIMENTO */
    //   "multa": null, /* PERCENTUAL DE MULTA APLICADO APÓS O VENCIMENTO */
    //   "validadeAposVencimento": 5, /* DIAS APÓS O VENCIMENTO QUE AINDA É POSSIEL REALIZAR PAGAMENTO */
    // };
    try {
      const response = await api.post( '/boletos/gerar', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async update ( req ) {
    // req = {
    //   CRCB_ID: item.CRCB_ID,
    //   CRCB_EMP_ID: item.CRCB_EMP_ID,
    //   CRCB_REMESSA: billet.data.nossoNumero,
    //   CRCB_URL_BOLETO: billet.data.boleto,
    //   CRCB_ENVIO_EMAIL: envioEmail,
    //   CRCB_ENVIO_WHATS: envioWhats,
    // };
    try {
      const response = await api.put( `/boletos/${ req.CRCB_ID }/${ req.CRCB_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async downBillet ( req ) {
    // req = {
    //   "nossoNumero": "74891121150039736789903123451001187340000000050", /* ID DO BOLETO SALVO EM CRCB_REMESSA */
    //   "boleto_emp_id": 1, /* ID DA EMPRESA */
    //   "bancoId": 4, /* ID DO BANCO, VEM DO NOSSO CADASTRO DE BANCO VINCULADO AO BANCO 748 */
    // };
    try {
      const response = await api.patch( `/boletos/baixa`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async PDF ( req ) {
    // req = {
    //   "linhaDigitavel": "74891121150039736789903123451001187340000000050", /* LINHA RETORNA AO VERIFICAR O BOLETO SE O MESMO AINDA NÃO FOI PAGO */
    //   "boleto_emp_id": 1, /* ID DA EMPRESA */
    //   "bancoId": 4, /* ID DO BANCO, VEM DO NOSSO CADASTRO DE BANCO VINCULADO AO BANCO 748 */
    // };    
    try {
      const response = await api.post( `/boletos/pdf`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async check ( req ) {
    // req = {
    //   "nossoNumero": 123456789, /* ID DO BOLETO SALVO EM CRCB_REMESSA */
    //   "boleto_emp_id": 1, /* ID DA EMPRESA */
    //   "bancoId": 4, /* ID DO BANCO, VEM DO NOSSO CADASTRO DE BANCO VINCULADO AO BANCO 748 */
    //   "codigoBeneficiario": 12345, /* CÓDIGO DO SICREDI DE TESTE ??? */
    // };
    try {
      const response = await api.post( `/boletos/verificar`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updateDate ( req ) {
    // req = {
    //   "nossoNumero": 123456789, /* ID DO BOLETO SALVO EM CRCB_REMESSA */
    //   "dataVencimento": "2024-08-20"
    // };
    try {
      const response = await api.post( `/boletos/atualizar`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new BoletosController();