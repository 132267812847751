import api from '../config/api';
class HistoricosController {
  /* eslint-disable */
//   async getLength(req, res){
//     let total = await api.post('/historico/registros/1', req);
//     let response = res;
//     response.total = total.data[0].TOT_REGISTROS;
//     return response.data;
//   }
  async index(req) {
    try {
      let response = await api.post(`/historico/${req.EMP_ID}`, req);
      if(!response.data.msg){
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.HIST_ID;
          item.text = item.HIST_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/historico', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/historico/${req.HIST_ID}/${req.HIST_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/historico/${req.HIST_ID}/${req.HIST_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/historico/pesquisar/${req.HIST_ID}/${req.HIST_EMP_ID}`);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.HIST_ID;
          item.text = item.HIST_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new HistoricosController();