const naturezasOperacao = [
  {
    path: '/naturezasoperacao',
    name: 'IndexNaturezasOperacao',
    meta: {
      requiresAuth: true,
      title: "Naturezas de Operação",
    },
    component: () => import('../../views/naturezasOperacao/IndexNaturezasOperacao.vue')
  },
  {
    path: '/naturezasoperacao/inserir',
    name: 'InsertNaturezasOperacao',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Naturezas de Operação",
    },
    component: () => import('../../views/naturezasOperacao/InsertNaturezasOperacao.vue')
  },
  {
    path: '/naturezasoperacao/alterar',
    name: 'AlterNaturezasOperacao',
    meta: {
        requiresAuth: true,
        title: "Editar Natureza de Operação",
    },
    component: () => import('../../views/naturezasOperacao/AlterNaturezasOperacao.vue')
  },
];
export default naturezasOperacao;