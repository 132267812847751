import api from '../config/api';
class UsuariosController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post(`/usuarios/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw  error;
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/usuarios/${req.EMP_ID}`, req);
      if(!response.data.msg) {
        response?.data?.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.USR_ID;
          item.text = item.USR_USUARIO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw  error;
    }
  }
  async created(req) {
    try {
      let response = await api.post('/usuarios', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/usuarios/${req.USR_ID}/${req.USR_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/usuarios/${req.USR_ID}/${req.USR_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async progUser(req) {
    try {
      let response = await api.get('/programas/menucadastrousuario', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async progUserAcess(req) {
    try {
      let response = await api.get(`/programasusuario/montamenu/${req.USUARIO}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async createProgUser(req) {
    try {
      let response = await api.post(`/programasusuario`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteprogUser(req) {
    try {
      let response = await api.delete(`/programasusuario/${req.PRGU_PROG_ID}/${req.PRGU_USUARIO}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updatedprogUser(req) {
    try {
      let response = await api.put(`/programasusuario/${req.PRGU_PROG_ID}/${req.PRGU_USUARIO}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async menuSubGrupo(req) {
    try {
      let response = await api.post(`/subgrupo/${req.EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async menuAcessSubGrupo(req) {
    try {
      let response = await api.post(`/subgrupousuario/${req.EMP_ID}/${req.USUARIO}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async createSubGrupo(req) {
    try {
      let response = await api.post(`/subgrupousuario`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delSunGrupo(req) {
    try {
      let response = await api.delete(`/subgrupousuario/${req.ID}/${req.EMP_ID}/${req.USUARIO}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async validaUser(req){
    try {
      let response = await api.post(`/validausuarios/${req.USR_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new UsuariosController();