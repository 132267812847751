import api from '../config/api';
class TributacoesController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( `/tributacao/registros/${ req.EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      let response = await api.post( `/tributacao/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.TICM_ID;
          item.text = item.TICM_DESCRICAO;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async created ( req ) {
    try {
      let response = await api.post( '/tributacao', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updated ( req ) {
    try {
      let response = await api.put( `/tributacao/${ req.TICM_ID }/${ req.TICM_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async delete ( req ) {
    try {
      let response = await api.delete( `/tributacao/${ req.TICM_ID }/${ req.TICM_EMP_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async search ( req ) {
    try {
      let response = await api.get( `/tributacao/pesquisar/${ req.TICM_ID }/${ req.TICM_EMP_ID }` );
      if ( !response.data.msg ) {
        response.data.forEach( item => {
          item.id = item.TICM_ID;
          item.text = item.TICM_DESCRICAO;
        });
      }
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async view ( req ) {
    try {
      let response = await api.get( `/tributacao/${ req.TICM_ID }/${ req.TICM_EMP_ID }` );
      if ( !response.data.msg ) {
        response.data.forEach( item => {
          item.id = item.TICM_ID;
          item.text = item.TICM_DESCRICAO;
        });
      }
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new TributacoesController();