const ordemVeiculoRoutes = [
    {
            path: '/ordemveiculo',
            name: 'IndexOrderServiceVeic',
            meta: {
              requiresAuth: true,
              title: "Ordem de Serviço",
        
        
            },
            component: () => import('../../views/ordemServico/orderVeiculo/IndexOrderServiceVeic.vue')
          },
        
          {
            path: '/ordemveiculo/alterar',
            name: 'EditOrderServiceVeic',
            meta: {
              requiresAuth: true,
              title: "Edição Ordem de Serviço",
        
        
            },
            component: () => import('../../views/ordemServico/orderVeiculo/EditOrderServiceVeic.vue')
          },
          {
            path: '/ordemveiculo/inserir',
            name: 'InsertOrderServiceVeic',
            meta: {
              requiresAuth: true,
              title: "Cadastro Ordem de Serviço",
        
        
            },
            component: () => import('../../views/ordemServico/orderVeiculo/InsertOrderServiceVeic.vue')
          },
          {
            path: '/ordemveiculo/relatorio',
            name: 'RelOrdemServico',
            meta: {
              requiresAuth: true,
              title: "Relatório Ordem de Serviço",
        
        
            },
            component: () => import('../../views/ordemServico/orderRelatorio/RelOrdemServico.vue')
          },
]
export default ordemVeiculoRoutes;