import api from '../config/api';

class OrderServicoMecanicoController {
  async index(req) {
    let response = await api.post(`/ordemservicomecanico/${req.NROS}/${req.EMP_ID}/OS`, req);
    return response.data;
  }
  async save(req) {
    let response = await api.post('/ordemservicomecanico', req);
    return response.data;
  }
  async edit(req) {
    let response = await api.put(`/ordemservicomecanico/${req.NROS}/${req.EMP_ID}/OS`, req);
    return response.data;
  }
  async delete(req) {
    let response = await api.delete(`/ordemservicomecanico/${req.ORDM_ORDS_ID}/${req.ORDM_ORDS_EMP_ID}/OS/${req.ORDM_ID}`);
    return response.data;
  }

}

export default new OrderServicoMecanicoController();