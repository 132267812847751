import api from '../../../config/api';
class SolicitacoesCalendarioController {
  /* eslint-disable */
  async index ( req ) {
    try {
      let response = await api.post( `/solicitacao/calendario/filtro`, req );
      return response?.data;
    } catch ( error ) {
      throw error
    }
  }
  async created ( req ) {
    try {
      let response = await api.post( `/solicitacao/calendario`, req );
      return response?.data;
    } catch ( error ) {
      throw error
    }
  }
  async update ( req ) {
    try {
      let response = await api.put( `/solicitacao/calendario/${req.id}`, req );
      return response?.data;
    } catch ( error ) {
      throw error
    }
  }
  async delete ( req ) {
    try {
      let response = await api.delete( `/solicitacao/calendario/${req.id}` );
      return response?.data;
    } catch ( error ) {
      throw error
    }
  }
}
export default new SolicitacoesCalendarioController();