import api from '../config/api';
// import { func } from '../functions/functions';

class OrcamentoProdController {
  async index(req) {
    let response = await api.post(`/orcamentoproduto/${req.ORCA_ID}/${req.EMP_ID}/OS`);
    return response.data;
  }
  async save(req) {
    let response = await api.post('/orcamentoproduto', req);
    return response.data;
  }
  async edit(req) {
    let response = await api.put(`/orcamentoproduto/${req.ORCP_ORCA_ID}/${req.EMP_ID}/OS/${req.ORCP_ID}`, req);
    return response.data;
  }
  async delete(req) {
    let response = await api.delete(`/orcamentoproduto/${req.ORCA_ID}/${req.EMP_ID}/OS/${req.ORCP_ID}`);
    return response.data;
  }
  async agrupar(req){
    let response = await api.put(`/orcamentoprodutoagrupar/${req.ORCP_ORCA_ID}/${req.EMP_ID}/OS/${req.ORCP_PROD_ID}/${req.EMP_ID}`, req);
    return response.data
}
}
export default new OrcamentoProdController();