
import api from '../config/api';

class ParametrosGenericosController {
  async index(req) {
    let response = await api.post(`/parametrosgenerico/filtros`, req);
    if (response.data.length > 0) {
      response?.data?.forEach((item, index) => {
        item["ORDEM"] = index % 2 === 0 ? 0 : 1;
      });
    }
    const result = response?.data || response;
    return result;
  }
  async indexFinanceiro(req) {
    let response = await api.post(`/parametrosfinanceiro/${req.EMP_ID}`, req);
    if (response.data.length > 0) {
      response?.data?.forEach((item, index) => {
        item["ORDEM"] = index % 2 === 0 ? 0 : 1;
      });
    }
    const result = response?.data || response;
    return result;
  }
}
export default new ParametrosGenericosController();