import api from '../config/api';

class VeiculosController {
  async getLength(req, res){
    let total = await api.post('/veiculo/registros/1', req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }
  async index(req) { 
    let response = await api.post(`/veiculo/1`, req);
    if(response?.data.length > 0){
      response?.data?.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.text = item.VEIC_PLACA1;
        item.id = item.VEIC_ID;
      });
    }
    let result = response.data.code != 500 ? response?.data : []
    return await this.getLength(req, result);
  }
  async save(req) {
    let response = await api.post(`/veiculo`, req);
    return response.data;
  }
  async update(req) {
    let response = await api.put(`/veiculo/${req.ID}/${req.VEIC_EMP_ID}`, req);
    return response.data;
  }
  async filter(req){
    let response = await api.post(`/veiculo/1`, req);
    return response.data[0];
  }
  async addVeiculoToPlaca(req){
    let response = await api.post(`/veiculo/automatico/${req.VEIC_EMP_ID}`, req);
    return response.data;
  }

//adiantamento
  async newAdiantamento(req) {
    let response = await api.post(`/agdoviagemadiantamento`, req);
    return response.data
  }

  // adiantamento reserva
  async getAdiantamento(req) {
    try{
      let response = await api.post(`/agdoviagemadiantamento/${req}/1`);
      return response?.data;
    }
    catch(error){
      console.log('@', error.response)
    }
   
  }
  async deleteAdiantamento(req) {
    let response = await api.delete(`/agdoviagemadiantamento/${req.id}/1/${req.id_agnd}`);
    return response.data;
  }



 


}
export default new VeiculosController();