const clientesRoutes = [
    {
        path: '/clientes',
        name: 'IndexCliente',
        meta: {
            requiresAuth: true,
            title: "Clientes",


        },
        component: () => import('../../views/clientes/IndexCliente.vue')
    },
    {
        path: '/clientes/inserir',
        name: 'InsertCliente',
        meta: {
            requiresAuth: true,
            title: "Cadastro Cliente",


        },
        component: () => import('../../views/clientes/InsertCliente.vue')
    },
    {
        path: '/clientes/alterar',
        name: 'EditCliente',
        meta: {
            requiresAuth: true,
            title: "Edição Cliente",


        },
        component: () => import('../../views/clientes/AlterCliente.vue')
    },
]
export default clientesRoutes;