const historicosRoutes = [
  {
    path: '/historicos',
    name: 'IndexHistoricos',
    meta: {
      requiresAuth: true,
      title: "Históricos",
    },
    component: () => import('../../views/historicos/IndexHistoricos.vue')
  },
  {
    path: '/historicos/inserir',
    name: 'InsertHistoricos',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Históricos",
    },
    component: () => import('../../views/historicos/InsertHistoricos.vue')
  },
  {
    path: '/historicos/alterar',
    name: 'AlterHistoricos',
    meta: {
      requiresAuth: true,
      title: "Editar Histórico",
    },
    component: () => import('../../views/historicos/AlterHistoricos.vue')
  }
]
export default historicosRoutes;