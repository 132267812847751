import api from '../config/api';
import { func } from '../functions/functions';

class OrdemPecasProdController {
    async index(req) {
        let response = await api.post(`/ordempecasproduto/${req.ORPP_ORPE_ID}`, req);
        let vlr_total = 0;
        let qtd_items = 0;
        if(response?.data && response?.data?.code != 500){
        response.data.forEach(element => {
            element.ORPP_QTD_FORMATED = func.convertMoney2(element.ORPP_QTD, 4);
             vlr_total += element.ORPP_VLR_TOTAL;
             qtd_items += element.ORPP_QTD;
            
        }); 
    }
    response.data.QTD = func.convertMoney2(qtd_items, 4);
    response.data.VLR_TOTAL = func.convertMoney2(vlr_total);
    return response?.data || response;
    }

    async save(req) {
        let response = await api.post('/ordempecasproduto', req);
        return response.data;
    }
    async delete(req) {
      let response = await api.delete(`/ordempecasproduto/${req.ORPP_ORPE_ID}/${req.ORPP_PROD_ID}/${req.ORPP_PROD_EMP_ID}/${req.ORPP_ID}`);
      return response.data;
    }

}
export default new OrdemPecasProdController();