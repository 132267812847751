const gruposRoutes = [
  {
    path: '/grupos',
    name: 'IndexGrupos',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Grupos",
    },
    component: () => import('../../views/grupos/IndexGrupos.vue')
  },
  {
    path: '/grupos/inserir',
    name: 'InsertGrupos',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Grupos",
    },
    component: () => import('../../views/grupos/ManageGroups.vue')
  },
  {
    path: '/grupos/alterar',
    name: 'AlterGrupos',
    meta: {
      requiresAuth: true,
      title: "Editar Grupo",
    },
    component: () => import('../../views/grupos/ManageGroups.vue')
  },
]
export default gruposRoutes;