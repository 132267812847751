
import api from '../config/api';

class OrdemServicoTotalizacao {
    async calculate(req) {
        const { ID, EMP_ID, SERIE} = req;
        let response = await api.post(`/ordemservicototalizacao/${ID}/${EMP_ID}/${SERIE}`);
        return response.data;
    }
}
export default new  OrdemServicoTotalizacao();