const condicoesVencimentoRoutes = [
  {
    path: '/condicoesvencimento',
    name: 'IndexCondicoesVencimento',
    meta: {
      requiresAuth: true,
      title: "Condições de vencimento",
    },
    component: () => import('../../views/condicoesVencimento/IndexCondicoesVencimento.vue')
  },
  {
    path: '/condicoesvencimento/inserir',
    name: 'InsertCondicoesVencimento',
    meta: {
      requiresAuth: true,
      title: "Cadastro de condições de vencimento",
    },
    component: () => import('../../views/condicoesVencimento/InsertCondicoesVencimento.vue')
  },
  {
    path: '/condicoesvencimento/alterar',
    name: 'AlterCondicoesVencimento',
    meta: {
      requiresAuth: true,
      title: "Editar condição de vencimento",
    },
    component: () => import('../../views/condicoesVencimento/AlterCondicoesVencimento.vue')
  },
];
export default condicoesVencimentoRoutes;