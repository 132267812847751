import api from '../../../config/api';
class MovimentacaoBarrisController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post('/movimentacaobarril/registros', req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error;
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/movimentacaobarril/filtros`, req);
      if (!response.data.msg) {
        response.data.forEach((item, index) => {
          item["ORDEM"] = index % 2 == 0 ? 0 : 1;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error;
    }
  }
  async created(req) {
    try {
      let response = await api.post('/movimentacaobarril', req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/movimentacaobarril/${req.MOVB_ID}`, req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/movimentacaobarril/${req.MOVB_ID}`);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
}
export default new MovimentacaoBarrisController();