const tiposServicoRoutes = [
  {
    path: '/tiposservico',
    name: 'IndexTiposServico',
    meta: {
      requiresAuth: true,
      title: "Tipos de Serviço",
    },
    component: () => import('../../views/tiposServico/IndexTiposServico.vue')
  },
  {
    path: '/tiposservico/inserir',
    name: 'InsertTiposServico',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Tipos de Serviço",
    },
    component: () => import('../../views/tiposServico/InsertTiposServico.vue')
  },
  {
    path: '/tiposservico/alterar',
    name: 'AlterTiposServico',
    meta: {
      requiresAuth: true,
      title: "Editar Tipo de Serviço",
    },
    component: () => import('../../views/tiposServico/AlterTiposServico.vue')
  },
]
export default tiposServicoRoutes;