import api from '../config/api';
class NaturezasOperacaoController {
  /* eslint-disable */
  async getLength(req, res){
    try {
      let total = await api.post(`/natureza/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/natureza/${req.EMP_ID}`, req);
      if(!response.data.msg) {
        response.data.forEach((item, index) => {
          item["ORDEM"] = index % 2 === 0 ? 0 : 1;
          item.id = item.NATO_ID;
          item.text = item.NATO_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/natureza', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/natureza/${req.NATO_ID}/${req.NATO_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    } 
  }
  async delete(req) {
    try {
      let response = await api.delete(`/natureza/${req.NATO_ID}/${req.NATO_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/natureza/pesquisar/${req.NATO_ID}/${req.NATO_EMP_ID}`);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.NATO_ID;
          item.text = item.NATO_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new NaturezasOperacaoController();