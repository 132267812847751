const modeloEquipamentoRoutes = [
    {
            path: '/modeloequipamento',
            name: 'IndexModeloEquipamento',
            meta: {
              requiresAuth: true,
              title: "Modelo Equipamento",
        
        
            },
            component: () => import('../../views/equipamentos/IndexModeloEquipamento.vue')
          },
        
        
          {
            path: '/modeloequipamento/inserir',
            name: 'InsertModeloEquipamento',
            meta: {
              requiresAuth: true,
              title: "Inserir Modelo Equipamento",
        
        
            },
            component: () => import('../../views/equipamentos/InsertModeloEquipamento.vue')
          },
          {
            path: '/modeloequipamento/alterar',
            name: 'AlterModeloEquipamento',
            meta: {
              requiresAuth: true,
              title: "Editar Modelo Equipamento",
        
        
            },
            component: () => import('../../views/equipamentos/AlterModeloEquipamento.vue')
          },
]
export default modeloEquipamentoRoutes;