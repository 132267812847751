const carregamentoBarrisPRoutes = [
  {
    path: '/carregamentobarrisp/inserir',
    name: 'InsertCarregamentoBarrisP',
    meta: {
      requiresAuth: true,
      title: "Inserir Barris no Carregamento",
    },
    component: () => import('../../../../views/projetos/gauchinho/carregamentoBarrisP/InsertCarregamentoBarrisP.vue')
  },
]
export default carregamentoBarrisPRoutes;