import api from '../config/api';
class UploadController {
    /* eslint-disable */
    upload(data) {
        let formData = new FormData();
        formData.append("file", data.IMAGE);
        return api.put(`/ordemservico/uploads/${data.NROS}/1/OS/${data.POSITION}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });
    }
    uploadAgendaViagem(data) {
        let formData = new FormData();
        formData.append("file", data.IMAGE);
        formData.append("AGVI_AGNV_ID", data.AGVI_AGNV_ID);
        formData.append("AGVI_FIL_ID", data.AGVI_FIL_ID);
        formData.append("AGVI_AGNV_EMP_ID", data.AGVI_AGNV_EMP_ID);
        formData.append("AGVI_USR_ID", data.AGVI_USR_ID);
        return api.post(`${data.ROUTE}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },

        });
    }

    delete(data) {
        return api.delete(`/ordemservico/uploads/${data.NROS}/1/OS/${data.POSITION}/${data.IMAGE}`);
    }
    
    deleteAgendaViagem(data){
        return api.delete(`/agdoviagemimg/${data.ID}/${data.EMP_ID}/${data.ID_IMG}/${data.NOME_IMG}`)
    }
    uploadEquipamentoImage(data){
        var formData = new FormData();
        formData.append("file", data.IMAGE);
        formData.append("EQIM_EQPT_ID", data.EQIM_EQPT_ID);
        formData.append("EQIM_EQPT_EMP_ID", data.EQIM_EQPT_EMP_ID);
        formData.append("EQIM_USR_ID", data.EQIM_USR_ID);
        formData.append("EQIM_FIL_ID", data.EQIM_FIL_ID);
        formData.append("EQIM_DESCRICAO", data.EQIM_DESCRICAO);

        return api.post(`/equipamentosimg`, formData, {
            
            headers: {
                "Content-Type": "multipart/form-data"
            },
            
        });
    }
  
}
export default new UploadController();