const coresRoutes = [
  {
    path: '/cores',
    name: 'IndexCores',
    meta: {
      requiresAuth: true,
      title: "Cores",
    },
    component: () => import('../../views/cores/IndexCores.vue')
  },
  {
    path: '/cores/inserir',
    name: 'InsertCores',
    meta: {
      requiresAuth: true,
      title: "Cadastro de cores",
    },
    component: () => import('../../views/cores/InsertCores.vue')
  },
  {
    path: '/cores/alterar',
    name: 'AlterCores',
    meta: {
      requiresAuth: true,
      title: "Editar cor",
    },
    component: () => import('../../views/cores/AlterCores.vue')
  },
];
export default coresRoutes;