import api from '../config/api';
class SubGruposController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( `/subgrupo/registros/${ req.EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  }
  async index ( req ) {
    try {
      let response = await api.post( `/subgrupo/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.GSUB_ID;
          item.text = item.GSUB_DESCRICAO;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  }
  async create(req) {
    try {
      let response = await api.post('/subgrupo', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async update(req) {
    try {
      let response = await api.put(`/subgrupo/${req.GSUB_ID}/${req.GSUB_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/subgrupo/${req.GSUB_ID}/${req.GSUB_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/subgrupo/pesquisar/${req.GSUB_ID}/${req.GSUB_EMP_ID}`);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.GSUB_ID;
          item.text = item.GSUB_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new SubGruposController();