const inteligenciaArtificial = [
    {
        path: '/inteligenciaartificial',
        name: 'chatGpt',
        meta: {
          requiresAuth: true,
          title: "Inteligência Artificial",
        },
        component: () => import('../../views/chatGpt/indexGpt.vue')
      },
]
export default inteligenciaArtificial;