const tipoEquipamentoRoutes = [
      {
    path: '/tipoequipamento',
    name: 'IndexTipoEquipamento',
    meta: {
      requiresAuth: true,
      title: "Tipo Equipamento",


    },
    component: () => import('../../views/equipamentos/IndexTipoEquipamento.vue')
  },

  {
    path: '/tipoequipamento/inserir',
    name: 'InsertTipoEquipamento',
    meta: {
      requiresAuth: true,
      title: "Inserir Tipo Equipamento",


    },
    component: () => import('../../views/equipamentos/InsertTipoEquipamento.vue')
  },
  {
    path: '/tipoequipamento/alterar',
    name: 'AlterTipoEquipamento',
    meta: {
      requiresAuth: true,
      title: "Editar Tipo Equipamento",


    },
    component: () => import('../../views/equipamentos/AlterTipoEquipamento.vue')
  },
]
export default tipoEquipamentoRoutes;