import api from '../../config/api';

class ClientesFamiliarController {
  
  async getLength(req, res){
    let total = await api.post(`/clientesfamiliar/registros/${req.CLIF_CLI_ID}/${req.CLIF_CLI_EMP_ID}`, req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }

    async index(req) {
        let response = await api.post(`/clientesfamiliar/${req.CLIF_CLI_ID}/${req.CLIF_CLI_EMP_ID}`, req);
        if(response.data.length > 0){
          response?.data?.forEach((item, index) => {
            item["ORDEM"] = index % 2 === 0 ? 0 : 1;
          });
        }
        const result = response?.data || response;
        return await this.getLength(req, result);
    }

    async save(req) {
        let response = await api.post('/clientesfamiliar', req);
        return response.data;
    }

    async updated(req) {
      let response = await api.put(`/clientesfamiliar/${req.CLIF_CLI_ID}/${req.CLIF_CLI_EMP_ID}/${req.CLIF_ID}`, req);
      return response.data;
    }

    async delete(req) {
      let response = await api.delete(`/clientesfamiliar/${req.CLIF_CLI_ID}/${req.CLIF_CLI_EMP_ID}/${req.CLIF_ID}`);
      return response.data;
    }

}
export default new ClientesFamiliarController();