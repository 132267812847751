import api from '../config/api';
class BancosController {
  /* eslint-disable */
  campos () {
    return {
      BCO_EMP_ID: "",
      BCO_FIL_ID: "",
      BCO_CID_EMP_ID: "",
      BCO_USR_ID: "",
      BCO_FORN_ID: "",
      BCO_CONTABIL: "",
      BCO_CODIGO: "",
      BCO_DESCRICAO: "",
      BCO_AGENCIA: "",
      BCO_AGENCIA_DIGITO: "",
      BCO_CONTA: "",
      BCO_CONTA_DIGITO:  "",
      BCO_ENDERECO: "",
      BCO_CID_ID: "",
      BCO_CEP: "",
      BCO_FONE: "",
      BCO_CONTATO: "",
      BCO_CEDENTE: "",
      BCO_TIPO: "",
      BCO_REL_ADM: "",
      BCO_DIAS_PROTESTO: "",
      BCO_DIAS_NEGATIVAR: "",
      BCO_JUROS_DIA: "",
      BCO_JUROS_TIPO: "",
      BCO_MULTA: "",
      BCO_ESPECIE: "",
      BCO_MODALIDADE: "",
      BCO_CONVENIO: "",
      BCO_LAYOUT: "",
      BCO_COD_TRANSMISSAO: "",
      BCO_MENSAGEM: "",
      BCO_VERSAO_ARQUIVO: "",
      BCO_VERSAO_LOTE: "",
    };
  };
  async getLength ( req, res ) {
    try{ 
      let total = await api.post( `/banco/registros/${ req.EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw  error;
    }
  };
  async index ( req ) {
    try {
      let response = await api.post( `/banco/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.BCO_ID;
          item.text = item.BCO_DESCRICAO;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async created ( req ) {
    try {
      let form = this.campos();
      for ( let chave in req ) {
        if ( req.hasOwnProperty( chave ) && form.hasOwnProperty( chave )) {
          form[ chave ] = req[ chave ];
        }
      }
      let response = await api.post( '/banco', form );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async updated ( req ) {
    try {
      let response = await api.put( `/banco/${ req.BCO_ID }/${ req.BCO_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    } 
  };
  async delete ( req ) {
    try {
      let response = await api.delete( `/banco/${ req.BCO_ID }/${ req.BCO_EMP_ID }`);
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
};
export default new BancosController();