const notFoundRoutes = [
    {
        path: '*',
        redirect: '/404'
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../../views/404.vue'),
        meta: {
            requiresAuth: true,
        }
    },
]
export default notFoundRoutes;