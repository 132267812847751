import api from '../../../config/api';
class BarrisController {
  /* eslint-disable */
  async index(req) {
    try {
      let response = await api.post(`/barril/filtro`, req);
      if (!response.data.msg) {
        response.data.forEach((item, index) => {
          item["ORDEM"] = index % 2 == 0 ? 0 : 1;
        });
      }
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async created(req) {
    try {
      let response = await api.post('/barril', req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/barril/${req.BAR_ID}`, req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/barril/${req.BAR_ID}`);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
}
export default new BarrisController();