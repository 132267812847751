import api from '../config/api';
import { func } from '../functions/functions';

class AgendamentoServicoController {
    
    async index(req) {
        try {
           let response = await api.post(`/agdoviagemservico/${req.ID}/${req.EMP_ID}`, req);
           if (response.data.length > 0) {
             response.data = response.data.map((item, index) => ({
               ...item,
               AGVS_VLR_TOTAL_FORMATED: func.convertMoney(item.AGVS_VLR_TOTAL),
               ORDEM: index % 2 === 0 ? 0 : 1,
             }));
           }
           return response.data || [];
        } catch (error) {
           console.error("Erro na requisição: ", error);
           return [];
        }
       }
  async create(req) {
    let response = await api.post(`/agdoviagemservico`, req);
    return response?.data || response;
  }
  async update(req) {
    let response = await api.put(`/agdoviagemservico/${req.GRUP_ID}/${req.GRUP_EMP_ID}`, req);
    return response.data;
  }
  async delete(req) {
    let response = await api.delete(`/agdoviagemservico/${req.AGNV_ID}/${req.EMP_ID}/${req.AGVS_ID}`, req);
    return response.data || response;
  } 
}
export default new AgendamentoServicoController();