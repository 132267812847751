const whatsappRoutes =[
  {
    path: '/whatsapp',
    name: 'IndexWhatsapp',
    params: { menu: false },
    meta: {
      requiresAuth: true,
      title: "Whatsapp",
    },
    component: () => import('../../views/whatsapp/IndexWhatsapp.vue')
  },
]
export default whatsappRoutes;