const bancosRoutes = [
  {
    path: '/bancos',
    name: 'IndexBancos',
    meta: {
      requiresAuth: true,
      title: "Bancos",
    },
    component: () => import('../../views/bancos/IndexBancos.vue')
  },
  {
    path: '/bancos/inserir',
    name: 'InsertBancos',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Bancos",
    },
    component: () => import('../../views/bancos/ManageBancos.vue')
  },
  {
    path: '/bancos/alterar',
    name: 'AlterBancos',
    meta: {
      requiresAuth: true,
      title: "Editar Banco",
    },
    component: () => import('../../views/bancos/ManageBancos.vue')
  }
]
export default bancosRoutes;