import axios from 'axios';
const env = process.env.VUE_APP_PAYMENT;
async function downloadPDFAsBlob ( url ) {
  try {
    const response = await axios({
      url: env + url,
      method: 'GET',
      responseType: 'blob', // Receber o arquivo como blob
    });
    const blob = response.data;
    return blob; // Retorna o caminho do arquivo temporário
  } catch ( error ) {
    console.error('Erro ao baixar e converter o PDF:', error);
  }
}
export default downloadPDFAsBlob;