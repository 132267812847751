const situacaoSolicitacoesRoutes = [
    {
        path: '/situacaosolicitacao',
        name: 'IndexSituacaoSolicitacao',
        meta: {
          requiresAuth: true,
          title: "Situação de solicitações",
        },
        component: () => import('../../views/solicitacoes/situacaoSolicitacao/IndexSituacao.vue')
      },
      {
        path: '/situacaosolicitacao/inserir',
        name: 'InsertSituacaoSolicitacao',
        meta: {
          requiresAuth: true,
          title: "Cadastrar situação de solicitações",
        },
        component: () => import('../../views/solicitacoes/situacaoSolicitacao/InsertSituacao.vue')
      },
      {
        path: '/situacaosolicitacao/alterar',
        name: 'AlterSituacaoSolicitacao',
        meta: {
          requiresAuth: true,
          title: "Editar situação de solicitações",
        },
        component: () => import('../../views/solicitacoes/situacaoSolicitacao/AlterSituacao.vue')
      }
]
export default situacaoSolicitacoesRoutes;