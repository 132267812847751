import api from '../../config/api';
import { func } from '../../functions/functions';

class ClientesHistoricoController {
  
    async index(req) {
        let response = await api.post(`/clienteshistorico/${req.CLIH_ID}/${req.CLIH_EMP_ID}`, req);
        if(response?.data.length > 0){
          response?.data?.forEach((item, index) => {
            item["ORDEM"] = index % 2 === 0 ? 0 : 1;
            item.CLIH_DATA_EMIS = func.convertDateTime(
              item.CLIH_DATA_EMIS
            );
            const convertDate = new Date(item.CLIH_HORA_EMIS)
            .toLocaleTimeString()
            .split(":");
          item.CLIH_HORA_EMIS = convertDate[0] + ":" + convertDate[1];
          });
        }
        const result = response?.data || response;
        return result;
    }

    async save(req) {
        let response = await api.post('/clienteshistorico', req);
        return response.data;
    }

    async update(req) {
      let response = await api.put(`/clienteshistorico/${req.CLIH_CLI_ID}/${req.CLIH_CLI_EMP_ID}/${req.CLIH_ID}/${req.CLIH_EMP_ID}`, req);
      return response.data;
    }
    async delete(req) {
      let response = await api.delete(`/clienteshistorico/${req.CLIH_CLI_ID}/${req.CLIH_CLI_EMP_ID}/${req.CLIH_ID}/${req.CLIH_EMP_ID}`);
      return response.data;
    }
}
export default new ClientesHistoricoController();