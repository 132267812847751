import axios from 'axios'
const urlRelatorios = process.env.VUE_APP_RELATORIOS

const api_rel = axios.create({ baseURL: urlRelatorios });

api_rel.interceptors.response.use(
  response => {
    return response
  },
  error => {
    throw { msg: error?.response?.data?.msg, code: error.response?.status }
  }
);

export default api_rel;