import api from '../config/api';
class AtualizaBancoController {
  /* eslint-disable */
  async index(req) {
    try {
      let response = await api.post(`/atualizacaobancodados/filtro`, req);
      if (response.data.registros.length > 0) {
        response.data.registros.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
        });
      }
      response.data.registros.total = response.data.count;
      return response.data.registros;
    } catch (error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/atualizacaobancodados', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/atualizacaobancodados/${req.atbd_id}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/atualizacaobancodados/${req.atbd_id}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new AtualizaBancoController();