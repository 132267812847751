import api from '../config/api';
class EmpresasController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( '/empresas/registros', req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  }
  async index ( req ) {
    try {
      let response = await api.post( '/empresas/filtro', req );
      if ( !response.data.msg ) {
        response.data.forEach( ( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.EMP_ID;
          item.text = item.EMP_DESCRICAO;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  }
  async created ( req ) {
    try {
      let response = await api.post( '/empresas', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async updated ( req ) {
    try {
      let response = await api.put( `/empresas/${ req.EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async delete ( req ) {
    try {
      let response = await api.delete( `/empresas/${ req.EMP_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async search ( req ) {
    try {
      let response = await api.get( `/empresas/pesquisar/${ req.FILTRO }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async updatedImage ( data ) {
    try {
      let formData = new FormData();
      formData.append( "file", data.IMAGE );
      let response = await api.put( `/empresas/uploads/${ data.ID }/${ data.ID_IMG }`, formData, { headers: { "Content-Type": "multipart/form-data" }});
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async deleteImage ( req ) {
    try {
      let response = await api.delete( `/empresas/uploads/${ req.ID }/${ req.ID_IMAGE }/${ req.NOME_IMAGE }`);
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
}
export default new EmpresasController();