const condicionalRoutes = [
  {
    path: '/condicional',
    name: 'IndexCondicional',
    meta: {
      requiresAuth: true,
      title: "Condicional",
    },
    component: () => import('../../views/condicional/IndexCondicional.vue')
  },
  {
    path: '/condicional/inserir',
    name: 'InsertCondicional',
    meta: {
      requiresAuth: true,
      title: "Adicionar Condicional",
    },
    component: () => import('../../views/condicional/InsertCondicional.vue')
  },
  {
    path: '/condicional/alterar',
    name: 'AlterCondicional',
    meta: {
      requiresAuth: true,
      title: "Editar Condicional",
    },
    component: () => import('../../views/condicional/AlterCondicional.vue')
  },
];
export default condicionalRoutes;