const gruposHistoricoPadrao = [
  {
    path: '/gruposhistoricopadrao',
    name: 'IndexGruposHistoricoPadrao',
    meta: {
      requiresAuth: true,
      title: "Grupos histórico padrão",
    },
    component: () => import('../../views/gruposHistoricoPadrao/IndexGruposHistoricoPadrao.vue')
  },
  {
    path: '/gruposhistoricopadrao/inserir',
    name: 'InsertGruposHistoricoPadrao',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Grupos histórico padrão",
    },
    component: () => import('../../views/gruposHistoricoPadrao/InsertGruposHistoricoPadrao.vue')
  },
  {
    path: '/gruposhistoricopadrao/alterar',
    name: 'AlterGruposHistoricoPadrao',
    meta: {
      requiresAuth: true,
      title: "Editar Grupo histórico padrao",
    },
    component: () => import('../../views/gruposHistoricoPadrao/AlterGruposHistoricoPadrao.vue')
  },
]
export default gruposHistoricoPadrao;