const dashboardRoutes = [
      {
    path: '/',
    name: 'Dashboard',

    meta: {
      requiresAuth: true,
      title: "Dashboard",
      subheader: [
        { text: "Inicio", route: "/" },
        { text: "Dashboard", route: "/" },
      ],
    },
    component: () => import('../../views/Dashboard.vue')
  }
]
export default dashboardRoutes;