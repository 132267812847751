const observacoesRoute = [
  {
    path: '/observacoes',
    name: 'IndexObservacoes',
    meta: {
      requiresAuth: true,
      title: "Observações",
    },
    component: () => import('../../views/observacoes/IndexObservacoes.vue')
  },
  {
    path: '/observacoes/inserir',
    name: 'InsertObservacoes',
    meta: {
      requiresAuth: true,
      title: "Cadastro de observações",
    },
    component: () => import('../../views/observacoes/InsertObservacoes.vue')
  },
  {
    path: '/observacoes/alterar',
    name: 'AlterObservacoes',
    meta: {
      requiresAuth: true,
      title: "Editar Observação",
    },
    component: () => import('../../views/observacoes/AlterObservacoes.vue')
  },
]
export default observacoesRoute;