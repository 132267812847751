const marcaVeiculoRoutes = [
    {
        path: '/marcaveiculo/inserir',
        name: 'InsertMarcaVeiculo',
        meta: {
            requiresAuth: true,
            title: "Inserir Marca Veículo",
        },
        component: () => import('../../views/veiculos/marca/InsertMarcaVeiculo.vue')
    },
    {
        path: '/marcaveiculo/alterar',
        name: 'AlterMarcaVeiculo',
        meta: {
            requiresAuth: true,
            title: "Editar Marca Veículo",
        },
        component: () => import('../../views/veiculos/marca/AlterMarcaVeiculo.vue')
    },
    {
        path: '/marcaveiculo',
        name: 'IndexMarcaVeiculo',
        meta: {
            requiresAuth: true,
            title: "Marca Veículo",
        },
        component: () => import('../../views/veiculos/marca/IndexMarcaVeiculo.vue')
    },
]
export default marcaVeiculoRoutes;