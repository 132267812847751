const estadosRoutes = [
  {
    path: '/estados',
    name: 'IndexEstados',
    meta: {
      requiresAuth: true,
      title: "Estados",
    },
    component: () => import('../../views/estados/IndexEstados.vue')
  },
  {
    path: '/estados/inserir',
    name: 'InsertEstados',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Estados",
    },
    component: () => import('../../views/estados/InsertEstados.vue')
  },
  {
    path: '/estados/alterar',
    name: 'AlterEstados',
    meta: {
      requiresAuth: true,
      title: "Editar Estado",
    },
    component: () => import('../../views/estados/AlterEstados.vue')
  }
]
export default estadosRoutes;